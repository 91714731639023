.timeline {
  overflow: auto;
  ul {
    list-style-type: none;
    padding: 0 0 0 7rem;
    margin: 0;
    position: relative;
    &:before {
      content: '';
      background-color: var(--bs-gray-300);
      height: 100%;
      margin: 0 0 0 -3px;
      position: absolute;
      width: 6px;
    }
    > li {
      margin: 0;
      padding: 0.5rem 0 0.5rem 1.5rem;
      position: relative;
      small.timeline-date {
        color: var(--bs-gray-500);
        padding: 0 1.5rem 0 0;
        position: absolute;
        left: 0;
        text-align: right;
        top: 0;
        transform: translate(-100%, 0.7rem);
        max-width: 7rem;
      }
      i {
        color: var(--primary);
        position: absolute;
        background-color: #ffffff;
        border-radius: 50%;
        border: 3px solid;
        border-color: currentColor;
        box-shadow: 0 0 0 3px #ffffff;
        left: 0;
        top: 0;
        padding: 4px;
        transform: translate(-50%, 0.9rem);
      }
      p {
        color: var(--bs-gray-600);
      }
    }
  }
  &.horizontal {
    white-space: nowrap;
    overflow-x: auto;
    ul {
      display: inline-block;
      padding: 3.5rem 0 0 0;
      text-align: center;
      &:before {
        height: 2px;
        margin: -1px 0 0 0;
        width: 100%;
      }
      > li {
        display: inline-block;
        padding: 1.5rem 1.5rem 0 1.5rem;
        small.timeline-date {
          left: 50%;
          padding: 0 0 1.5rem;
          transform: translate(-50%, -100%);
        }
        i {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
