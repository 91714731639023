// -----------------------------------------------------------------------------
// This file contains styles that are specific to the verify email page.
// -----------------------------------------------------------------------------

.verify-email {
  background: no-repeat center;
}

.send-verify-email {
  background: no-repeat bottom;
  background-size: contain;

  .container {
    background-color: rgba(235, 242, 255, 0.8);
  }
}

.verification-modal {
  .verification-sent {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    z-index: 10;
    animation-name: fadeOut;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
}
