.input-text {
  padding: 1px;
  > input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--gray);
    outline: none;
    width: 100%;
    &:focus {
      border-color: var(--primary);
    }
  }
}
