.new-project {
  h3 {
    font-size: 1.5rem;
    display: block !important;
    margin: 1rem 0;
  }
  .ant-list {
    .ant-list-items {
      display: flex;
      flex-wrap: wrap;

      > a {
        display: block;
      }
      .ant-card {
        background: #ffffff;
        border-color: transparent;
        box-shadow: 0 0 0 1px #f0f0f0;
        margin: 0 1rem 1rem 0;

        &.ant-card-type-inner {
          box-shadow: 0 0 0 3px $primary !important;

          .ant-card-body {
            padding: 24px !important;
          }
        }

        &.ant-card-hoverable:hover {
          box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
            0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
        }

        .ant-card-cover {
          background-color: #f0f0f0;
          border-bottom: 1px solid #f0f0f0;
          height: 130px;
          img {
            border-radius: 2px;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
        &[data-type='mobile_app'] {
          .ant-card-cover {
            height: 340px;
          }
        }
        &[data-type='flow'],
        &[data-type='backend'],
        &[data-type='web'] {
          .ant-card-cover {
            height: 100px;
          }
        }
        .ant-card-meta-title,
        .ant-card-meta-description {
          font-size: 0.8rem;
        }
      }
      .ant-list-item {
        background: #ffffff;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
        margin: 0 1rem 1rem 0;
        padding: 1rem;
        height: 100%;
        .ant-list-item-meta {
          flex-direction: column;
          .ant-list-item-meta-avatar,
          .ant-avatar {
            margin: 0;
            width: 100%;
          }
          .ant-avatar {
            height: 200px;
            > img {
              object-fit: contain;
              padding: 1rem;
            }
          }
          .ant-list-item-meta-content {
            width: 100%;
          }
        }
      }
    }

    &.new-project-types {
      .ant-card {
        height: 100%;
        .ant-card-cover {
          height: 160px;
          img {
            object-fit: contain !important;
          }
        }
      }
    }

    &.new-project-style {
      .ant-card {
        height: 100px !important;
        width: 100px !important;

        &.ant-card-type-inner {
          .ant-card-body {
            padding: 0.5rem !important;
          }
        }

        .ant-card-body {
          height: 100%;
          padding: 0.5rem;

          .palette {
            border-radius: 2px;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
          .palette {
            > div {
              display: flex;
              height: 100%;
              div {
                background: currentColor;
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }
  .ant-upload.ant-upload-drag {
    position: relative;
    z-index: 1;
    &.ant-upload-drag-uploading {
      background: linear-gradient(
        to bottom,
        rgba(250, 250, 250, 1) 0%,
        rgba(250, 250, 250, 0.8) 20%,
        rgba(250, 250, 250, 0.1) 50%,
        rgba(250, 250, 250, 0) 100%
      );
      span {
        display: none;
      }
    }
    &.ant-upload-drag-hover:not(.ant-upload-disabled) {
      color: $primary;
      * {
        color: inherit;
      }
    }
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    border-radius: 54px;
    width: 256px;
    height: 256px;

    img {
      background-color: #ffffff;
      border-radius: 23px;
      box-shadow: 0 0 0 2px #f0f0f0;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
  .ant-upload-list {
    background: #fafafa;
    bottom: 0;
    max-height: 320px;
    padding: 1rem 0;
    position: absolute;
    overflow: hidden;
    width: 240px;
    display: flex;
    flex-direction: column-reverse;
    .ant-upload-list-text-container {
      .ant-upload-list-item {
        height: auto;
        margin: 0;
        .ant-upload-list-item-info {
          padding: 0.15rem 0 0.15rem 1rem;
          .ant-upload-text-icon {
            height: 28px;
          }
        }
        .ant-upload-list-item-progress {
          display: none;
        }
      }
    }
  }
}

.icon-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  min-width: 32px;
  min-height: 32px;
  &:not(.ant-avatar-square) {
    border-radius: 54px;
  }
  &.ant-avatar {
    svg {
      stroke: #ffffff;
      stroke-width: 5px;
    }
  }
  svg {
    fill: transparent;
    stroke: $gray-300;
  }
  span {
    position: absolute;
  }
}

[data-key='screenshots'],
[data-key='preview_url'],
[data-key='uid'] {
  .remove-item-button {
    display: none !important;
  }
}
.template-actions {
  opacity: 0;
  visibility: hidden;
}
.ant-list-item:hover {
  .template-actions {
    opacity: 1;
    visibility: visible;
  }
}
