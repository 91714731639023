/**
 * Border
 */
:root {
  --bs-border-color: rgba(0, 0, 0, 0.1) !important;
}

.rounded-bottom {
  border-radius: 0 0 0.25rem 0.25rem !important;
}

/**
 * Box shadow
 */

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

/**
 * Carousel
 */
.carousel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
    &:before {
      color: #ffffff;
      font-size: 3rem;
    }
  }

  .carousel-inner,
  .carousel-item {
    height: 100%;
    position: relative;
    img {
      width: 100%;
    }

    .carousel-caption {
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 80%);
      bottom: 0;
      display: block !important;
      left: 0;
      padding: 6rem 2rem 1rem;
      position: absolute;
      right: 0;

      > h3 {
        font-weight: normal;
      }
    }
  }
  .carousel-indicators {
    order: 1;
    position: absolute;
    margin: 1rem 0 0;

    li {
      background-color: transparent;
      border: none;
      border-radius: 100%;
      box-shadow: inset 0 0 0 2px $primary;
      height: 10px;
      opacity: 1;
      width: 10px;
      transition: background 0.3s ease;

      &.active {
        background-color: $primary;
      }
    }
  }
}

/**
 * Custom input: checkbox, radio
 */

.form-control-lg {
  border-radius: 1.5rem !important;
  + .eye {
    padding: 0 0.75rem !important;
  }
}

.custom-control {
  .custom-radio {
    > input {
      top: 50% !important;
      margin-top: -0.75rem;
      margin-left: -1.4rem;
    }
  }

  .custom-control-label {
    &:before,
    &:after {
      top: 50% !important;
      margin-top: -8px;
    }
    &:empty {
      &:before,
      &:after {
        left: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  .custom-control-input {
    margin-left: 0;
    z-index: 1;
  }

  &[data-some-checked='true'] {
    .custom-control-label {
      z-index: 0;
      &:before {
        background-color: $primary;
      }
      &:after {
        content: '-';
        color: #fff;
        padding: 0 3px;
        font-size: 24px;
        line-height: 0.9rem;
      }
    }
    .custom-control-input:checked {
      ~ .custom-control-label {
        &:after {
          content: '';
        }
      }
    }
  }
}

/**
 * Display
 */
.d-grid {
  align-items: center;
  display: grid;
  gap: 1rem;

  image,
  video {
    width: 100%;
  }
}

/**
 * Form
 */
.form-group {
  position: relative;
}

/**
 * Buttons
 */
button:focus {
  outline: none !important;
}

.btn-lg,
.btn-group-lg > .btn {
  --bs-btn-border-radius: 1.5rem !important;
}

.btn-group {
  [type='radio'] {
    position: absolute;
    margin: 0;
    opacity: 0;
    z-index: -1;
  }
}

.btn-link {
  text-decoration: none !important;
}

/**
 * Disabled
 */

[disabled],
.disabled {
  opacity: 0.65;
  cursor: default !important;
}

/**
 * Dropdown
 */

.dropdown {
  .btn {
    box-shadow: none;
  }
  .dropdown-menu {
    outline: none;
  }
  .dropdown-header {
    color: $gray-400;
    font-size: 0.8rem;
    outline: none;
    text-transform: uppercase;
  }
  &[data-arrow='false'] {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }
}

/**
 * Tables
 */

thead {
  th {
    color: #c1c3c6;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: #f8f9fa !important;
      }
    }
  }
}

table {
  span {
    + .icon {
      position: absolute;
      margin-top: -3px;
    }
  }
}

/**
 * Forms
 */
select {
  &.rounded-left {
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
}

/**
 * Line height
 */
.line-height-0 {
  line-height: 0rem !important;
}
.line-height-1 {
  line-height: 1rem !important;
}

/**
 * Opacity
 */
.opacity-1 {
  opacity: 1 !important;
}

/**
 * Cursor
 */
.cursor-default {
  cursor: default !important;
}

/**
 * Modals
 */
.modal {
  .fixed-top {
    margin: 0;
    max-width: 100%;

    .modal-content {
      border: none;
      border-radius: 0;
      box-shadow: 0 2rem 2rem -1rem rgba(0, 0, 0, 0.15);
      max-height: 100vh;
    }
  }

  .modal-header {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    .modal-title {
      flex-grow: 1;

      img {
        width: 120px;
      }
    }
    .close {
      text-shadow: none;
      font-weight: 400;
      font-size: 2.8rem;
      line-height: 1rem;
      > span {
        display: flex;
        height: 24px;
        width: 24px;
      }
    }
  }

  .modal-content {
    border: none;
    box-shadow: 0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.25);
  }

  .modal-footer {
    border: none;

    .btn,
    a {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  &.fade {
    &.show .modal-dialog {
      &.modal-bottom {
        transform: translate(0, 0);
      }
    }

    .modal-dialog {
      &.modal-bottom {
        bottom: 0;
        position: fixed;
        transform: translate(0, 25%);
      }

      &.modal-bottom,
      &.modal-top {
        width: 100%;
        max-width: 100%;
        margin: 0;

        .modal-content {
          border: none;
          border-radius: 0;

          .modal-header,
          .modal-footer {
            padding: 1rem 2rem;
          }

          .modal-body {
            padding: 2rem 0;
          }
        }
      }
    }
  }

  .fullscreen {
    height: 100vh;
    margin: auto;
    max-width: 100vw;
    width: 100vw;
    .modal-content {
      border: none;
      border-radius: 0;
      height: 100vh;
      margin: auto;
      width: 100vw;
      .modal-header {
        align-items: center;
        height: 56px;
        .close {
          color: $primary;
          margin: -1rem 0 -1rem -1rem;
          opacity: 1;
          order: 1;
          &:hover {
            color: darken($primary, 20%);
          }

          span {
            display: none;
          }
        }
        .modal-title {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 0 auto 0 0;
          order: 2;
          width: 100%;
          white-space: nowrap;

          > span {
            flex-grow: 1;
            margin-right: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 0;
            white-space: nowrap;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        padding: 0;
      }
    }
  }
}

/**
 * Nav
 */

.nav {
  .nav-item {
    .nav-link {
      color: var(--bs-primary);
      &:hover,
      &:focus {
        filter: brightness(1.2);
      }
    }
  }
}
