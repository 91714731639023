// -----------------------------------------------------------------------------
// This file contains all styles related to the editor event.
// -----------------------------------------------------------------------------

.node-event-wrapper {
  font-family: consolas, monaco, 'lucida console', 'courier new', courier,
    monospace, sans-serif;
  display: flex;
  line-height: 1.5rem;
  margin: 0;
  position: relative;

  .node-event-wrapper {
    align-items: center;
    border: 2px dashed;
    border-right: none;
    border-radius: 0.5rem 0 0 0.5rem;
    display: flex;
    height: 92px;
    padding: 0.25rem;
    z-index: 0;
  }

  .node-back-item {
    background-color: $gray-100;
    border: 2px dashed;
    border-radius: 0.5rem 0 0 0.5rem;
    border-right: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    width: 60px;
    min-width: 60px;
    min-height: 70px;
    z-index: 1;

    > .ant-btn {
      flex-grow: 1;
    }
  }

  .node-item {
    align-items: center;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    height: 80px;
    justify-content: flex-start;
    margin: 0 -13px 0 0;
    padding: 0;
    position: relative;
    transition: all 0.2s ease;
    user-select: none;
    min-width: 80px;

    &[data-event-id] {
      min-width: 160px;

      > .node-event-wrapper {
        margin: 0 0 0 -10px;
      }
    }

    &:first-child:not(:last-child) {
      > .node-item-bg {
        g.first-child {
          display: block;
        }
      }
    }
    &:last-child:not(:first-child) {
      margin: 0;
      > .node-item-bg {
        g.last-child {
          display: block;
        }
      }
      > .node-item-content {
        > .node-item-menu-button {
          right: 0.35rem;
        }
      }
    }
    &:first-child:last-child {
      margin: 0;
      > .node-item-bg {
        g.alone {
          display: block;
        }
      }
      > .node-item-content {
        > .node-item-menu-button {
          right: 0.35rem;
        }
      }
    }
    &:not(:first-child):not(:last-child) {
      > .node-item-bg {
        g.default {
          display: block;
        }
      }
    }

    &:hover {
      > .node-item-bg {
        path {
          &:first-child {
            filter: contrast(0.4) brightness(1.8);
          }
        }
      }
    }

    &.node-new-item {
      border: 2px dashed;
      border-radius: 0 0.5rem 0.5rem 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 0;
      width: 80px;
      min-width: 80px;
      min-height: 92px;
      z-index: 1;

      .node-new-item-plus,
      .node-new-item-paste {
        border: none;
        color: $gray-900;
        overflow: hidden;
        padding: 0;
        width: 100%;
        transition: all 0.2s ease;
        svg {
          transition: width 0.2s ease;
        }
      }
      .node-new-item-plus {
        height: 100%;
      }
      .node-new-item-paste {
        display: none;
        height: 0;
        max-height: 0;
      }

      &[data-paste='true'] {
        .node-new-item-plus,
        .node-new-item-paste {
          svg {
            width: 1rem !important;
          }
        }
        .node-new-item-paste {
          display: block;
          height: 100%;
          max-height: 100%;
        }
      }
    }

    &.ant-popover-open {
      > .node-item-bg {
        path {
          &:first-child {
            filter: contrast(0.6) brightness(1.8);
          }
        }
      }
    }

    &.sortable-chosen {
      cursor: grabbing;

      > .node-item-bg {
        path {
          &:first-child {
            filter: contrast(0.6) brightness(1.8);
          }
        }
      }
    }

    > .node-item-bg {
      position: absolute;
      width: 160px;
      height: 80px;
      padding: 1px;
      z-index: 1;
      g {
        display: none;

        path {
          fill: currentColor;
          transition: all 0.2s ease;
          &:first-child {
            filter: contrast(0.15) brightness(1.8);
          }

          &:last-child {
            fill: none;
            stroke: currentColor;
            stroke-width: 4px;
          }
        }
      }
    }

    > .node-item-content {
      width: 160px;
      min-width: 160px;
      height: 80px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      z-index: 2;

      > * {
        z-index: 1;
      }

      > .node-item-icons {
        color: currentColor;
        position: absolute;
        display: flex;
        left: 0.35rem;
        top: 0.35rem;
        transition: all 0.2s ease;
        > .node-item-condition-button,
        > .node-item-comment-button {
          color: currentColor;
          padding: 0;
          width: 24px;
          min-width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 1.2rem;
          }
        }
      }

      > .node-type {
        position: absolute;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding-right: 5px;
        align-items: center;
        justify-content: center;
        z-index: 0;
        > span {
          color: $gray-900;
          font-size: 0.8rem;
        }
        > small {
          filter: contrast(0.6) brightness(0.8);
          font-size: 0.6rem;
          opacity: 0.6;
          max-width: calc(100% - 1rem);
          white-space: nowrap;
          line-height: 0.6rem;
          position: absolute;
          overflow: hidden;
          text-overflow: ellipsis;
          left: 0.5rem;
          bottom: 0.5rem;
          right: 0.5rem;
          overflow: hidden;
          text-align: center;
        }
      }

      > .node-item-menu-button {
        position: absolute;
        top: 0.35rem;
        right: 1rem;
      }
    }

    > .node-event-wrapper {
      border: none;
      padding: 0;
      visibility: hidden;
      z-index: 1;

      .node-item {
        &[data-key^='on'],
        &[data-key='else'],
        &[data-key='what'] {
          display: flex;
          transition: all 0.2s ease;
          border-radius: 0;
          height: 74px;
          min-width: 80px;
          width: 80px;
          padding: 0;
          transition: all 0.2s ease;
          visibility: visible;
          z-index: 1;

          &:before {
            content: '';
            background-color: currentColor;
            border-top: 2px dashed #fff;
            border-bottom: 2px dashed #fff;
            box-shadow: 0 0 0 4px currentColor;
            position: absolute;
            width: 100%;
            height: calc(100% - 4px);
            transition: all 0.2s ease;
          }

          &:hover {
            > .node-event-wrapper-zoom {
              > svg {
                transform: scale(1.3);
              }
            }
            > .node-event-wrapper {
              &:before {
                filter: contrast(1.1) brightness(0.9);
              }
            }
          }

          > .node-event-wrapper-zoom {
            color: #ffffff;
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            visibility: visible;
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
              transition: all 0.2s ease;
            }
          }
          > .node-event-wrapper {
            opacity: 0.3;
            transform: scale(0.7);
            transform-origin: left;
            visibility: visible;
            pointer-events: none;
          }
        }
      }
    }

    > .node-new-item {
      display: none;
    }
  }
}

.event-comments,
.event-condition,
.event-params {
  width: fit-content !important;

  .json-node-item {
    flex-wrap: nowrap !important;
  }
  .ant-popover-inner,
  .ant-popover-inner-content,
  .json-node-wrapper,
  .json-node-item,
  .json-node-value,
  .autocomplete-wrapper,
  [contenteditable] {
    max-width: 100vw !important;
    width: fit-content !important;
  }
  .autocomplete {
    position: fixed !important;
  }
}

.event-comments {
  .item-button {
    display: none !important;
  }
}

.node-item-menu {
  min-width: 140px !important;
  .ant-dropdown-menu-item-divider {
    margin-top: 1.2rem;
  }
  .ant-dropdown-menu-item-group-title {
    background-color: #ffffff;
    font-size: 0.8rem;
    display: flex;
    margin: -20px 0 0;
    position: relative;
    width: fit-content;
  }
  .ant-dropdown-menu-item-group-list {
    margin: 0;
    .ant-dropdown-menu-item {
      svg {
        color: rgba(0, 0, 0, 0.35);
        width: 1rem;
      }
      .ant-dropdown-trigger {
        display: block;
        margin: -5px -36px;
        width: calc(100% + 48px);
        height: 32px;
        padding: 5px 12px 5px 36px;
      }
    }
  }
}

.node-new-item-menu {
  .ant-dropdown-menu {
    background-color: $gray-200;
    display: grid;
    grid-template-columns: 120px 120px 120px 120px;
    gap: 1px;
    padding: 0;
    .ant-dropdown-menu-item {
      background-color: #ffffff;
      &:hover {
        background-color: $gray-200;
      }
    }
  }
}
