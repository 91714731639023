// -----------------------------------------------------------------------------
// This file contains all styles related to the preview of the site/application.
// -----------------------------------------------------------------------------

#app {
  > .preview-wrapper {
    padding: 0;
    transform: scale(1) !important;
    > div {
      border-radius: 0;
      &:before {
        height: 100vh;
        width: 100vw;
      }
    }
    .ref-wrapper {
      display: none !important;
    }
    .content-wrapper {
      height: 100vh;
      min-height: 100vh;
      width: 100vw;
      margin: 0 !important;
      transform: scale(1) !important;
    }
  }
}

.preview-modal {
  > div {
    top: unset !important;
    left: unset !important;
  }
  .react-draggable {
    width: 354px;
    height: 705px;
    > .ant-modal-content {
      width: 100%;
      height: 100%;
    }
    .ant-modal-body {
      position: relative;
      padding: 0;
      width: 354px;
      height: 630px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &[data-preview='MOBILE_LANDSCAPE'] {
      width: 630px;
      height: 429px;
      .ant-modal-body {
        padding: 0;
        width: 630px;
        height: 354px;
      }
    }

    &[data-project-type='flow'],
    &[data-project-type='web'] {
      main {
        transform-origin: top left;
      }
      &[data-resolution='1920x1080'] {
        main {
          width: 1920px !important;
          min-width: 1920px !important;
          max-width: 1920px !important;
          height: 1080px !important;
          min-height: 1080px !important;
          max-height: 1080px !important;
          transform: scale(0.328) !important;
        }
      }
      &[data-resolution='1600x900'] {
        main {
          width: 1600px !important;
          min-width: 1600px !important;
          max-width: 1600px !important;
          height: 900px !important;
          min-height: 900px !important;
          max-height: 900px !important;
          transform: scale(0.393) !important;
        }
      }
      &[data-resolution='1280x720'] {
        main {
          width: 1280px !important;
          max-width: 1280px !important;
          min-width: 1280px !important;
          height: 720px !important;
          max-height: 720px !important;
          min-height: 720px !important;
          transform: scale(0.492) !important;
        }
      }
      &[data-resolution='1024x720'] {
        main {
          width: 1024px !important;
          max-width: 1024px !important;
          min-width: 1024px !important;
          height: 578px !important;
          max-height: 578px !important;
          min-height: 578px !important;
          transform: scale(0.614) !important;
        }
      }
      &[data-preview='MOBILE_PORTRAIT'] {
        &[data-resolution='1920x1080'] {
          main {
            height: 1920px !important;
            min-height: 1920px !important;
            max-height: 1920px !important;
            width: 1080px !important;
            min-width: 1080px !important;
            max-width: 1080px !important;
            transform: scale(0.328) !important;
          }
        }
        &[data-resolution='1600x900'] {
          main {
            height: 1600px !important;
            min-height: 1600px !important;
            max-height: 1600px !important;
            width: 900px !important;
            min-width: 900px !important;
            max-width: 900px !important;
          }
        }
        &[data-resolution='1280x720'] {
          main {
            height: 1280px !important;
            max-height: 1280px !important;
            min-height: 1280px !important;
            width: 720px !important;
            max-width: 720px !important;
            min-width: 720px !important;
          }
        }
        &[data-resolution='1024x720'] {
          main {
            height: 1024px !important;
            max-height: 1024px !important;
            min-height: 1024px !important;
            width: 578px !important;
            max-width: 578px !important;
            min-width: 578px !important;
          }
        }
      }
    }
  }

  .ant-modal-header {
    padding: 0;
    white-space: nowrap;
    .ant-modal-title {
      > div {
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 16px 60px 16px 24px;
        height: 54px;
      }

      .ant-modal-close-x {
        cursor: pointer;
      }
    }
  }

  .resize-button {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: se-resize;
  }

  .hover {
    box-shadow: 0 0 0 1px $primary, 0 0 4px 2px $primary;
    opacity: 0.9;
    &:hover {
      box-shadow: none;
      opacity: 1 !important;
    }
  }
  .active {
    box-shadow: 0 0 0 2px $primary, 0 0 4px 3px $primary;
  }
}

.preview-wrapper {
  background: radial-gradient($gray-500, $gray-200);
  display: flex;
  justify-content: center;
  width: 0;
  flex-grow: 1;
  overflow: hidden;

  > div {
    transform: scale(0);
    transition: all 0.2s ease;
    will-change: transform;
  }

  .dialog {
    max-width: 500px;
  }

  main {
    background: #fff;
    border-radius: 1rem;
    overflow: hidden !important;
    transition: all 0.2s ease;
    will-change: width, min-width, max-width, height, min-height;
    > .main-content {
      height: 0;
      flex-grow: 1;
      overflow: auto;
      width: 100%;
      > div {
        width: 100%;
        > div {
          min-height: 100%;
          height: 100%;
        }
      }
    }
  }

  &[data-project-type='flow'] {
    .steps {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      scroll-snap-type: x mandatory;
      scroll-snap-points-x: repeat(100%);
      width: 100%;
      > div {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: center;
        position: relative;
      }
    }
  }

  &[data-project-type='mobile_app'] {
    align-items: center;
    main {
      width: 354px !important;
      height: 630px !important;
    }

    &[data-preview='MOBILE_LANDSCAPE'] {
      main {
        width: 630px !important;
        height: 354px !important;
      }
    }
  }

  &[data-project-type='flow'],
  &[data-project-type='web'] {
    align-items: flex-start;
    overflow-y: auto;
    > div {
      height: 0;
      padding-top: 2rem;
    }
    main {
      transform-origin: center top;
      background-color: #ffffff;
    }
    &[data-preview='MOBILE_LANDSCAPE'] {
      &[data-resolution='1920x1080'] {
        main {
          width: 1920px;
          min-width: 1920px;
          max-width: 1920px;
          min-height: 1080px;
        }
      }
      &[data-resolution='1600x900'] {
        main {
          width: 1600px;
          min-width: 1600px;
          max-width: 1600px;
          min-height: 900px;
        }
      }
      &[data-resolution='1280x720'] {
        main {
          width: 1280px;
          max-width: 1280px;
          min-width: 1280px;
          min-height: 720px;
        }
      }
      &[data-resolution='1024x720'] {
        main {
          width: 1024px;
          max-width: 1024px;
          min-width: 1024px;
          height: 578px;
          min-height: 578px;
        }
      }
    }
    &[data-preview='MOBILE_PORTRAIT'] {
      &[data-resolution='1920x1080'] {
        main {
          min-height: 1920px;
          width: 1080px;
          min-width: 1080px;
          max-width: 1080px;
        }
      }
      &[data-resolution='1600x900'] {
        main {
          min-height: 1600px;
          width: 900px;
          min-width: 900px;
          max-width: 900px;
        }
      }
      &[data-resolution='1280x720'] {
        main {
          min-height: 1280px;
          width: 720px;
          max-width: 720px;
          min-width: 720px;
        }
      }
      &[data-resolution='1024x720'] {
        main {
          min-height: 1024px;
          width: 578px;
          max-width: 578px;
          min-width: 578px;
        }
      }
    }
  }

  .ant-modal-header {
    padding: 0;
    white-space: nowrap;
    .ant-modal-title {
      > div {
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 16px 60px 16px 24px;
        height: 54px;
      }

      .ant-modal-close-x {
        cursor: pointer;
      }
    }
  }

  .resize-button {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: se-resize;
  }

  .hover {
    box-shadow: 0 0 0 1px $primary, 0 0 4px 2px $primary;
    opacity: 0.9;
    &:hover {
      box-shadow: none;
      opacity: 1 !important;
    }
  }
  .active {
    box-shadow: 0 0 0 2px $primary, 0 0 4px 3px $primary;
  }
}

.preview {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  &[data-direction='column'] {
    flex-direction: column;
  }

  &[data-scroll='true'] {
    &[data-direction='column'] {
      > div[data-height='fill'] {
        height: 0;
        overflow-y: auto;
      }
    }
    &[data-direction='row'] {
      > div[data-width='fill'] {
        width: 0;
        overflow-x: auto;
      }
    }
  }

  > div {
    display: flex;

    &[data-height='fill'],
    &[data-width='fill'] {
      flex-grow: 1;
    }
    &[data-height='fill'] {
      height: 0;
    }
    &[data-width='fill'] {
      width: 0;
    }
  }

  .component-wrapper {
    &[data-width='100%'] {
      width: -webkit-fill-available !important;
    }
    &[data-height='fill'] {
      height: unset !important;
    }
    &[data-height='fill'],
    &[data-width='fill'] {
      flex-grow: 1;
    }
    &[data-height='wrap'],
    &[data-height='wrap'] > .visor {
      height: fit-content;
    }
  }

  [data-halign='center'],
  [data-halign='center'] > .component-wrapper > div {
    justify-content: center;
  }
  [data-halign='end'],
  [data-halign='end'] > .component-wrapper > div {
    justify-content: flex-end;
  }
  [data-valign='middle'],
  [data-valign='middle'] > .component-wrapper > div {
    align-items: center;
  }
  [data-valign='bottom'],
  [data-valign='bottom'] > .component-wrapper > div {
    align-items: flex-end;
  }

  [data-position='absolute'] {
    position: absolute;
    top: 0;
    left: 0;
    &[data-halign='center'] {
      left: 50%;
      transform: translate(-50%, 0);
      &[data-valign='middle'] {
        transform: translate(-50%, -50%);
      }
    }
    &[data-halign='end'] {
      left: unset;
      right: 0;
    }
    &[data-valign='middle'] {
      top: 50%;
      transform: translate(0, -50%);
    }
    &[data-valign='bottom'] {
      top: unset;
      bottom: 0;
    }
  }

  iframe {
    border: none;
    display: block;
  }

  &[data-direction='column'] {
    > div {
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;

      &[data-position='absolute'] {
        > div {
          position: relative !important;
        }
      }
    }
  }

  &[data-direction='row'] {
    > div {
      > .component-wrapper {
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}

.preview[data-type='mobile_app'] {
  [data-component-id] {
    overflow: hidden;
    background-position: center;
    background-size: cover;

    &[data-type='banner'] {
      align-items: center !important;
      display: flex;
      justify-content: center !important;
      width: 100% !important;
      position: relative;
      &:before {
        content: '';
        padding-top: 15%;
        display: block;
      }
      > img {
        position: absolute;
        width: 100%;
      }
      &[data-format='regular'] {
        &:before {
          padding-top: 15%;
        }
      }
      &[data-format='smart'] {
        &:before {
          padding-top: 20%;
        }
      }
      &[data-format='large'] {
        &:before {
          padding-top: 31%;
        }
      }
      &[data-format='rectangle'] {
        &:before {
          padding-top: 78%;
        }
      }
      &[data-format='leaderboard'] {
        &:before {
          padding-top: 12%;
        }
      }
    }
    &[data-type='button'] {
      button {
        width: 100%;
      }
      > div {
        align-items: center;
        border-radius: 4rem;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
        display: flex;
        height: 4rem;
        justify-content: center;
        padding: 0.75rem;
        width: 4rem;
        > div {
          background-position: center;
          background-size: contain;
          height: 75%;
          width: 75%;
        }
      }
    }

    &[data-type='canvas'] {
      canvas {
        display: block;
        height: 100%;
        width: 100%;
      }
    }

    &[data-type='card'] {
      .card {
        height: 100%;
      }
    }

    &[data-type='cropper'] {
      align-items: center;
      display: flex;
      justify-content: center;
      .cropper {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        .img {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          &:before {
            content: '';
            display: block;
            padding-top: 66.66%;
          }
        }
        &[data-mode='16:9'] {
          .frame-wrapper {
            width: 80%;
            &:before {
              padding-top: 56.25%;
            }
          }
        }
        &[data-mode='9:16'] {
          .frame-wrapper {
            width: 30%;
            &:before {
              padding-top: 177.78%;
            }
          }
        }
        &[data-mode='4:3'] {
          .frame-wrapper {
            width: 70%;
            &:before {
              padding-top: 75%;
            }
          }
        }
        &[data-mode='3:4'] {
          .frame-wrapper {
            &:before {
              padding-top: 133.33%;
            }
          }
        }
        &[data-mode='free'] {
          .frame-wrapper {
            &:before {
              padding-top: 50%;
            }
          }
        }
        &[data-mode='fit'] {
          .frame-wrapper {
            height: 100%;
            width: 100%;
            &:before {
              display: none;
            }
          }
        }
        &[data-mode='square'] {
          .frame-wrapper {
            &:before {
              padding-top: 100%;
            }
          }
        }
        &[data-mode='circle'],
        &[data-mode='circle_square'] {
          .frame-wrapper {
            border-radius: 10rem;
            &:before {
              padding-top: 100%;
            }
          }
        }
        .frame-wrapper {
          align-items: center;
          color: #00000096;
          box-shadow: 0 0 0 100rem;
          display: flex;
          justify-content: center;
          position: absolute;
          width: 40%;
          &:before {
            content: '';
            display: block;
            padding-top: 100%;
          }
          .frame {
            color: $primaryDark;
            border: 1px solid;
            height: 100%;
            position: absolute;
            width: 100%;
            .guides,
            .handlers {
              position: absolute;
              width: 100%;
              height: 100%;
            }
            .guides {
              color: $primary;
              position: absolute;
              > div {
                position: absolute;
                &:nth-child(1),
                &:nth-child(2) {
                  height: 100%;
                  border-left: 1px solid;
                }
                &:nth-child(3),
                &:nth-child(4) {
                  width: 100%;
                  border-top: 1px solid;
                }
                &:nth-child(1) {
                  left: 33.33%;
                }
                &:nth-child(2) {
                  left: 66.66%;
                }
                &:nth-child(3) {
                  top: 33.33%;
                }
                &:nth-child(4) {
                  top: 66.66%;
                }
              }
            }
          }
          .handlers {
            color: $primaryDark;
            height: 100%;
            position: absolute;
            width: 100%;
            > div {
              background-color: currentColor;
              border-radius: 2rem;
              height: 2rem;
              position: absolute;
              width: 2rem;
              &:nth-child(1) {
                left: -1.1rem;
                top: -1.1rem;
              }
              &:nth-child(2) {
                right: -1.1rem;
                top: -1.1rem;
              }
              &:nth-child(3) {
                bottom: -1.1rem;
                right: -1.1rem;
              }
              &:nth-child(4) {
                bottom: -1.1rem;
                left: -1.1rem;
              }
            }
          }
        }
      }
    }

    &[data-type='dropdown'] {
      overflow: visible;

      .dropdown {
        &[data-image='true'] {
          .dropdown-toggle {
            padding: 0.25rem;
          }
        }
        .dropdown-toggle,
        .dropdown-menu {
          width: 100%;
        }
        .dropdown-toggle {
          display: flex;
          align-items: center;

          > div {
            display: flex;
          }
          > [data-type='image'] > div {
            display: flex;
          }
          > [data-type='text'] > div {
            width: 100%;
          }
        }
      }
    }

    &[data-type='form'] {
      overflow: visible;
    }

    &[data-type='group'] {
      display: flex;
      &[data-direction='column'] {
        flex-direction: column;
      }
      &[data-height='fill'],
      &[style*='height:'] {
        > .visor {
          height: 100%;
        }
      }
      > .visor {
        width: 100%;
        &[data-scroll='true'] {
          &[data-direction='column'] {
            > div {
              overflow-y: auto;
            }
            overflow-y: auto;
          }
          &[data-direction='row'] {
            > div {
              overflow-x: auto;
            }
            overflow-x: auto;
          }
        }
      }
    }

    &[data-type='image'] {
      > div {
        height: 100%;
        width: 100%;
        &[data-scale] {
          &[data-scale='crop'] {
            background-size: cover;
            img {
              object-fit: cover;
            }
          }
          &[data-scale='fill'] {
            img {
              object-fit: fill;
            }
          }
        }
      }

      &[data-height='100%'][data-width='100%'] {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
          object-fit: fill;
          position: absolute;
          width: 100%;
        }
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    &[data-type='input'] {
      overflow: visible;
    }

    &[data-type='list'] {
      height: 100% !important;
      &[data-direction='column'] {
        flex-direction: column;
        grid-auto-flow: row;
        overflow-y: auto;
      }
      &[data-direction='row'] {
        > div {
          grid-auto-flow: column;
          white-space: nowrap;
          overflow-x: auto;
        }
      }
      > div {
        display: grid;
      }
    }

    &[data-type='nav'] {
      overflow: visible;
      .nav {
        .nav-link {
          display: flex;
          align-items: center;
        }
        &[data-menu-style='icon'] {
          .nav-link {
            > .component-wrapper {
              margin: 0 !important;
            }
          }
        }
      }
    }

    &[data-type='text'] {
      > div {
        display: flex;
        height: 100%;
        width: 100%;
        word-break: break-word;
      }

      [data-align='flex-start'] {
        justify-content: flex-start !important;
        text-align: left;
      }
      [data-align='center'] {
        justify-content: center !important;
        text-align: center;
      }
      [data-align='flex-end'] {
        justify-content: flex-end !important;
        text-align: right;
      }

      [data-valign='top'] {
        align-items: flex-start !important;
      }
      [data-valign='middle'] {
        align-items: center !important;
      }
      [data-valign='bottom'] {
        align-items: flex-end !important;
      }
    }

    &[data-type='timeline'] {
      .timeline {
        ul {
          list-style-type: none;
          padding: 0 0 0 5rem;
          margin: 0;
          position: relative;
          &:before {
            content: '';
            background-color: rgba(0, 0, 0, 0.5);
            height: 100%;
            margin: 0 0 0 -1px;
            position: absolute;
            width: 2px;
          }
          > li {
            margin: 0;
            padding: 0.5rem 0 0.5rem 1.5rem;
            position: relative;
            small.timeline-date {
              color: var(--black50);
              padding: 0 1.5rem 0 0;
              position: absolute;
              left: 0;
              top: 0;
              transform: translate(-100%, 0.7rem);
            }
            i {
              color: var(--primary);
              position: absolute;
              border-radius: 50%;
              background-color: currentColor;
              left: 0;
              top: 0;
              padding: 6px;
              transform: translate(-50%, 1rem);
            }
            p {
              color: var(--black50);
            }
          }
        }
        &.horizontal {
          white-space: nowrap;
          overflow-x: auto;
          ul {
            display: inline-block;
            padding: 3.5rem 0 0 0;
            text-align: center;
            &:before {
              height: 2px;
              margin: -1px 0 0 0;
              width: 100%;
            }
            > li {
              display: inline-block;
              padding: 1.5rem 1.5rem 0 1.5rem;
              small.timeline-date {
                left: 50%;
                padding: 0 0 1.5rem;
                transform: translate(-50%, -100%);
              }
              i {
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }

    &[data-type='inputtext'] {
      padding: 1px;
      > input {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $secondaryLight;
        outline: none;
        width: 100%;
        &:focus {
          border-color: $primary;
        }
      }
    }

    &[data-type='map'],
    &[data-type='pdfviewer'],
    &[data-type='web'] {
      iframe {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }

    &[data-type='web'] {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    &[data-type='player'] {
      display: flex;
      flex-direction: column;
      > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
      audio {
        outline: none;
        width: 100%;
      }
    }

    &[data-type='map'] {
      height: 300px;
      width: 100%;
      .no-apikey {
        display: block;
        padding: 0.25rem;
      }
    }

    &[data-type='range'] {
      align-items: center;
      display: flex;
      .noUi-target {
        background: rgba(0, 0, 0, 0.15);
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin: 0.5rem 1rem;
        &.noUi-horizontal {
          height: 4px;
          width: 100%;
          .noUi-origin {
            align-items: center;
            height: 4px;
          }
        }
        &.noUi-vertical {
          height: 100%;
          width: 4px;
          .noUi-origin {
            justify-items: center;
            width: 4px;
          }
        }
        .noUi-base {
          .noUi-connects {
            border-radius: 0;
          }
          .noUi-origin {
            display: flex;
          }
        }
      }

      .noUi-connect,
      .noUi-origin {
        position: absolute !important;
      }

      .noUi-handle {
        background-color: #fff;
        border: none;
        border-radius: 100rem;
        box-shadow: none;
        cursor: pointer;
        height: 0.8rem;
        min-height: 0rem;
        min-width: 0rem;
        outline: none;
        position: absolute;
        right: -0.3rem;
        top: unset;
        transition: all 0.2s ease;
        width: 0.8rem;
        &:before,
        &:after {
          display: none;
        }
      }
    }

    &[data-type='scanner'] {
      div {
        align-items: center;
        box-shadow: inset 0 0 6rem -2rem rgba(0, 0, 0, 0.8);
        display: flex;
        height: 100%;
        justify-content: center;
        perspective: 500px;
        position: absolute;
        width: 100%;
        canvas {
          background-color: #ffffff;
          padding: 5%;
          position: absolute;
          width: 90% !important;
          height: auto !important;
          max-height: 150%;
          transform: rotate3d(1, 0, 0, 10deg);
        }
      }
    }

    &[data-type='select'] {
      overflow: visible;
      .dropdown-toggle {
        align-items: center;
        background-color: inherit;
        border: none;
        border-radius: 0 !important;
        box-shadow: none !important;
        color: inherit;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .dropdown-menu {
        border: none;
        border-radius: 0;
        box-shadow: 0 0.5rem 1rem rgba(#000000, 0.35);
        color: inherit;
        margin: 0;
        padding: 0;
        width: 100%;
        .dropdown-item {
          background-color: transparent !important;
          color: inherit !important;
        }
      }
    }

    &[data-type='stripe_checkout'] {
      overflow-y: auto;
      .LOADING-loads {
        border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.05);
        overflow: hidden;
        position: relative;
      }
      .LOADING-loads:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 200%;
        background: linear-gradient(
          90deg,
          hsla(0, 0%, 100%, 0),
          hsla(0, 0%, 100%, 0.65) 50%,
          hsla(0, 0%, 100%, 0)
        );
        transform: translateX(-100%);
      }
      .LOADING-container {
        -webkit-animation: enter 0.3s 0s;
        animation: enter 0.3s 0s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        pointer-events: none;
        position: absolute;
        padding: 0 16px;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999999;
      }
      .LOADING-hidden {
        -webkit-animation: exit 0.3s;
        animation: exit 0.3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      .LOADING-checkoutContainer {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 16px 0;
        width: 100%;
        max-width: 380px;
        box-sizing: border-box;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 0 auto;
      }
      .LOADING-header {
        min-height: 32px;
      }
      .LOADING-backArrow,
      .LOADING-header {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
      }
      .LOADING-backArrow {
        width: 14px;
        height: 32px;
        margin-right: 8px;
        color: rgba(0, 0, 0, 0.2);
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .LOADING-logo {
        width: 28px;
        height: 28px;
        border-radius: 100%;
      }
      .LOADING-productSummary {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 32px;
        margin-bottom: 10px;
      }
      .LOADING-productTitle {
        width: 85px;
        height: 16px;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        border-radius: 20px;
        margin: 2px 0;
      }
      .LOADING-productPrice {
        width: 123px;
        height: 24px;
        margin: 8px 0 32px;
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
        border-radius: 20px;
      }
      .LOADING-productImageContainer {
        margin: 0 auto 20px;
      }
      .LOADING-productImage,
      .LOADING-productImageContainer {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
      }
      .LOADING-productImage {
        width: 120px;
        height: 120px;
      }
      .LOADING-paymentTitle {
        display: none;
      }
      .LOADING-payment-oneRow,
      .LOADING-payment-twoRow {
        width: 100%;
      }
      .LOADING-button {
        width: 100%;
        height: 44px;
        margin-top: 29px;
      }
      .LOADING-payment-oneRow {
        height: 46px;
      }
      .LOADING-payment-twoRow {
        height: 90px;
      }
      .LOADING-mb38 {
        margin-bottom: 38px;
      }
      .LOADING-checkoutPayment {
        transform: translateY(2rem);
      }
      .LOADING-container--single .LOADING-checkoutPayment {
        margin-top: 0;
      }
      .LOADING-container--single .LOADING-productSummary {
        display: none;
      }
      .LOADING-container--single .LOADING-checkoutOverview {
        padding-bottom: 0;
        margin-bottom: 66px;
      }
      .LOADING-footer {
        color: rgba(26, 26, 26, 0.5);
        font-size: 12px;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: -1rem;
        left: 0;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Helvetica Neue, Ubuntu, sans-serif;
      }
      .LOADING-footer svg {
        position: relative;
        fill: rgba(26, 26, 26, 0.4);
      }
      .LOADING-container--single .LOADING-footer {
        bottom: -2rem;
      }
    }
  }
}
