/**
 * Focus
 */

.select__control {
  &.select__control--is-focused {
    color: $input-focus-color;
    background-color: $input-focus-bg !important;
    border-color: $input-focus-border-color !important;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}
