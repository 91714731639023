/**
* Button
**/

.ant-btn {
  &:not([disabled]):not(.ant-btn-dangerous):not(.ant-btn-primary):not(.ant-btn-link):not(.ant-btn-text) {
    &:hover,
    &:focus {
      color: $primary;
      border-color: $primary;
    }
  }

  &.ant-btn-icon-only {
    vertical-align: 0;
  }

  &.ant-btn-link {
    &:not([disabled]):not(.ant-btn-dangerous) {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        border-color: transparent;
        color: $primaryDark;
      }
    }
  }

  &.ant-btn-primary {
    &:not([disabled]):not(.ant-btn-dangerous) {
      background-color: $primary;
      border-color: $primary;
      color: #ffffff;
      &:hover,
      &:focus,
      &:active {
        background-color: $primaryDark;
        border-color: $primaryDark;
      }
      > * {
        color: #ffffff;
      }
    }
    &[disabled] {
      > * {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  &.ant-btn-text {
    &:not([disabled]):not(.ant-btn-dangerous) {
      color: rgba(0, 0, 0, 0.85);
      &:hover,
      &:focus,
      &:active {
        background: rgba(0, 0, 0, 0.058);
        border-color: transparent;
      }
    }
    &[disabled] {
      > * {
        color: rgba(0, 0, 0, 0.25) !important;
      }
      &:hover,
      &:focus,
      &:active {
        > * {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
  }
}

/**
* Checkbox
**/

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border-color: $primary;
    }
  }
}

.ant-checkbox {
  &:hover {
    .ant-checkbox-inner {
      border-color: $primary;
    }
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    &:after {
      background-color: $primary;
    }
  }
}

.ant-checkbox-input {
  &:focus {
    + .ant-checkbox-inner {
      border-color: $primary;
    }
  }
}

.ant-checkbox-checked {
  &:after {
    border: 1px solid $primary;
  }
  .ant-checkbox-inner {
    background-color: $primary;
    border-color: $primary;
  }
}

/**
* Close
**/

.ant-drawer-close {
  padding: 15px 20px;
}

/**
* Drawer
**/

.ant-drawer-content-wrapper {
  width: 30vw !important;
  min-width: 305px !important;
}

/**
* Dropdown
**/

.ant-dropdown {
  .ant-dropdown-menu {
    max-height: 100vh;
    overflow: auto;
  }
}

/**
* Input
**/

.ant-slider + .ant-input,
.ant-slider + .ant-input-number {
  margin-left: 0.5rem;
}
.ant-input {
  width: auto;

  &:focus,
  &:hover,
  &.ant-input-focused {
    border-color: $primary;
    box-shadow: none;
  }
}
.ant-input-password {
  .ant-input {
    width: 100%;
  }
}
.ant-input-number {
  width: 65px;

  &:focus,
  &:hover,
  &.ant-input-number-focused {
    border-color: $primary;
    box-shadow: none;
  }
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: $primary;
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primary;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $primary;
  box-shadow: 0 0 0 2px rgba($primary, 0.2);
}

/**
* List
**/

.ant-list-item-meta-title {
  > a:hover {
    color: $primary;
  }
}

/**
* Menu
**/

.ant-menu-item:hover,
.ant-menu-item a:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: $primary;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: $primary;
  background-color: lighten($primary, 35%);
}

.ant-menu {
  background: transparent;
  min-width: 0 !important;

  &.ant-menu-sub {
    &:empty {
      display: none;
    }
  }

  .ant-menu-item-group-title {
    text-transform: uppercase;
  }

  .ant-menu-item {
    &:hover,
    &.ant-menu-item-selected {
      color: $primary;
    }
    &.ant-menu-item-selected,
    &:active,
    .ant-menu-submenu-title:active {
      background-color: lighten($primary, 35%);
    }
    &:after {
      border-color: $primary;
    }
  }

  .ant-menu-submenu-arrow {
    &:before,
    &:after {
      background: $gray-500;
    }
  }

  .ant-menu-submenu-selected {
    border-color: $primary;
    color: $primary;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    &:hover,
    &.ant-menu-item-selected {
      border-color: $primary;
      color: $primary;
      .ant-menu-submenu-arrow {
        &:before,
        &:after {
          background: $primary;
        }
      }
    }
  }
}

/**
* Message
**/

.ant-message {
  top: 50px;

  .ant-message-notice {
    padding: 0 1rem 0 0 !important;
    text-align: right;
    visibility: hidden;
    .ant-message-notice-content {
      border-radius: 0.3rem;
      overflow: hidden;
      position: relative;
      visibility: visible;
      .ant-message-custom-content {
        display: flex;
        align-items: center;
      }
    }
  }

  .anticon {
    margin-right: 1rem;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: left;

    li {
      list-style: none;
    }
  }
}

/**
* Modals
**/

.fullscreen-modal {
  width: 100vw !important;
  height: 100vh;
  margin: 0;
  max-width: 100%;
  top: 0;
  padding: 0;

  > .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    > .ant-modal-body {
      flex-grow: 1;
      height: 100%;
      overflow: auto;
    }
  }
}

.draggable-modal {
  bottom: unset !important;
  right: unset !important;

  .ant-modal {
    position: fixed;
    width: 0 !important;
    height: 0 !important;
  }
  .ant-modal-header {
    cursor: move;
    height: 54px;
  }
  .ant-modal-body {
    transform-origin: top left;
  }
  .ant-modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

/**
* Notifications
**/

.ant-notification {
  .ant-notification-notice-description {
    .modal-footer {
      border: none;
      padding: 1.25rem 0 0;
      .btn-link {
        text-transform: uppercase;
      }
    }
  }
}

/**
* Popover
**/

.ant-popover-inner-content {
  max-height: calc(100vh - 32px);
  overflow: auto;

  [contenteditable] {
    display: block;
    padding: 0.25rem 0.5rem;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    min-width: 50px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0 4px rgba($primary, 0.12);
      outline: none;
    }
  }
}

/**
* Progress
**/

.ant-progress {
  .ant-progress-bg {
    background: $primary;
  }
  .ant-progress-circle-path {
    stroke: linear-gradient(150deg, $secondary 0%, rgba($primary, 0.8) 100%);
  }
}

.ant-message {
  .ant-progress {
    align-items: center;
    display: flex;
    position: absolute;
    height: 22px;
    width: calc(100% - 2rem);

    &.ant-progress-status-success {
      .ant-progress-bg {
        background: $success;
      }
    }

    .ant-progress-inner {
      border-radius: 0;
      height: 3px;
      left: -16px;
      position: absolute;
      top: -10px;
      width: calc(100% + 32px);
      .ant-progress-bg,
      .ant-progress-bg:before {
        border-radius: 0;
      }
    }
    .ant-progress-text {
      display: flex;
      justify-content: center;
      .anticon {
        margin: 0;
      }
    }
  }
}

/**
* Radio
**/

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $primary;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $primary;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $primary;
}
.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $primary;
  border-color: $primary;
}
.ant-radio-button-wrapper {
  &:focus-within {
    box-shadow: 0 0 0 3px rgba($primary, 0.12);
  }
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $primary;
  border-color: $primary;
  &:hover:before {
    background-color: $primary;
  }
  &:focus-within {
    box-shadow: 0 0 0 3px rgba($primary, 0.12);
  }
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $primary;
  border-color: $primary;
}

/**
* Ribbon
**/

.ant-ribbon {
  background-color: $primary;
}

/**
* Select
**/

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $gray-400;
  border-radius: 0.25rem;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: $primary;
}
.ant-select {
  &:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border-color: $gray-400;
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        border-color: $primary !important;
        box-shadow: 0 0 0 4px rgba($primary, 0.12) !important;
      }
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: lighten($primary, 35%);
}

/**
* Slider
**/

.ant-slider {
  flex-grow: 1;

  .ant-slider-rail {
    background-color: $gray-300;
  }
  &:hover {
    .ant-slider-rail {
      background-color: $gray-400;
    }
  }
  .ant-slider-track {
    background-color: $primary !important;
  }
  .ant-slider-handle {
    border: 2px solid $primary !important;

    &:focus {
      box-shadow: 0 0 0 5px rgba($primary, 0.12) !important;
    }
  }
}

/**
* Space
**/

.ant-space {
  &.justify-content-between {
    width: 100%;
    justify-content: space-between;
  }
}

/**
* Spinner
**/

.ant-spin-dot-item {
  background-color: $primary;
}

/**
* Steps
**/

.ant-steps-item-icon .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $primary;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $primary;
}
.ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: $primary;
  stroke-width: 8px;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: $primary;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $primary;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  background: none;
}

/**
* Switch
**/

.ant-switch {
  &.ant-switch-checked {
    background-color: $primary !important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba($primary, 0.2) !important;
  }
  .ant-switch-inner {
    display: flex;
    align-items: center;
    svg {
      max-width: 0.75rem;
      min-width: 0.75rem;
      width: 0.75rem;
      max-height: 0.75rem;
      min-height: 0.75rem;
      height: 0.75rem;
    }
  }
  .ant-switch-loading-icon {
    color: $primary !important;
  }
}

/**
* Tabs
**/

.ant-tabs {
  flex-direction: column;
  flex-grow: 1;
  display: flex;

  &.ant-tabs-card {
    .ant-tabs-nav {
      margin: 0;
      transition: none;
      &:before {
        display: none;
      }
      .ant-tabs-tab {
        cursor: default;
        background: transparent;
        border: none;
        margin: 0 !important;
        padding: 0;
        position: unset;

        .ant-tabs-tab-btn {
          & > div {
            align-items: center;
            border: none;
            background-color: transparent;
            border-radius: 0.5rem 0.5rem 0 0;
            color: $primaryText !important;
            display: flex;
            font-weight: normal;
            height: 36px;
            margin: 4px 0 0 !important;
            padding: 0.5rem 1rem;
            position: relative;
            transition: all 0.3s ease !important;
            &:hover {
              box-shadow: -8px 0 0 -7px transparent;
              color: $primaryText;
              z-index: 1;
              &:before,
              &:after {
                color: $primaryText;
              }
            }

            [type='close'] {
              align-items: center;
              border-radius: 2rem;
              color: $primaryText;
              cursor: pointer;
              display: flex;
              font-size: 1.2rem;
              height: 32px;
              justify-content: center;
              margin: 0 -0.75rem 0 0.5rem;
              transition: background 0.3s ease;
              width: 32px;
            }

            &.active {
              background: $primaryText;
              box-shadow: -8px 0 0 -7px transparent;
              color: $primaryText;
              transition: none !important;
              z-index: 2;
              &:before,
              &:after {
                color: $primaryText;
                transition: none !important;
              }
            }

            &:before,
            &:after {
              background: transparent;
              bottom: 0;
              color: transparent;
              content: '';
              width: 1rem;
              height: 1rem;
              position: absolute;
              border-radius: 0.75rem;
              transition: all 0.3s ease !important;
            }
            &:before {
              box-shadow: 0.5rem 0.5rem 0 0 currentColor;
              right: 100%;
            }
            &:after {
              box-shadow: -0.5rem 0.5rem 0 0 currentColor;
              left: 100%;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder {
      flex-direction: column;
      flex-grow: 1;
      display: flex;
      .ant-tabs-content {
        flex-grow: 1;
      }
    }
  }

  &:not(.ant-tabs-card) {
    .ant-tabs-nav {
      margin: 0;
      &:before {
        display: none;
      }
      .ant-tabs-nav-list {
        display: flex;
        flex-grow: 1;

        .ant-tabs-ink-bar {
          background: $primary;
        }

        .ant-tabs-tab {
          flex-grow: 1;
          font-size: 0.7rem;
          font-weight: normal;
          justify-content: center;
          line-height: 40px;
          margin: 0;
          padding: 0 0.5rem;
          text-align: center;
          text-transform: uppercase;

          &:hover,
          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $primary;
            }
          }
        }
      }
      .ant-tabs-nav-more {
        padding: 0 0.5rem;
      }
    }
  }
}

/**
* Tooltip
**/

.ant-tooltip-disabled-compatible-wrapper {
  cursor: default !important;
}

/**
* Typography
**/

.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: $primary;
  &:active,
  &:hover {
    color: lighten($primary, 15%);
  }
}

/**
* Upload
**/

.ant-upload.ant-upload-select-picture-card:hover,
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $primary;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: $primary;
}
