// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
  white-space: nowrap;

  &.icon {
    background-color: transparent;
    border: none;
    border-radius: 3rem;
    height: 40px;
    padding: 0;
    text-align: center;
    width: 40px;

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled).hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
  svg {
    max-width: 1.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    max-height: 1.5rem;
    min-height: 1.5rem;
    height: 1.5rem;
  }
}
