// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  padding-bottom: 80px;

  .nav {
    flex-direction: column;
    justify-content: flex-start;
    .nav-item {
      padding: 0.25rem 0;
      .nav-link {
        padding: 0;
      }
    }
  }
}
