// -----------------------------------------------------------------------------
// This file contains styles that are specific to the dashboard page.
// -----------------------------------------------------------------------------

.dashboard {
  .sidebar-dashboard {
    background: linear-gradient(
      135deg,
      lighten($primary, 35%) 60%,
      lighten($secondary, 32%) 100%
    );
    padding-bottom: 1.5rem;

    .ant-list-header,
    .ant-list-item {
      border-color: transparent;
    }
    .ant-list-item {
      padding: 0.5rem 1rem 0.5rem 1.5rem;

      .ant-btn-link {
        padding: 0;
        margin: 0;
        border: 0;
        line-height: 0;
        color: var(--bs-body-font-family);
        font-size: 0.8rem;
        transition: none;
        height: auto;
      }
    }

    .ant-menu {
      .ant-menu-submenu-title {
        margin: 0;
        padding: 0;
        line-height: 0;
        color: var(--bs-body-font-family);
        font-size: 0.8rem;
        transition: none;
        height: auto;

        &:hover {
          color: $primaryDark !important;
          fill: $primaryDark !important;
        }

        &:active {
          background-color: transparent !important;
        }

        .ant-menu-title-content {
          transition: none;
        }
      }
      .ant-menu-submenu-arrow {
        display: none;
      }

      &.user-menu {
        .ant-menu-submenu-title {
          border-radius: 100%;
          display: inline-block;
          height: auto !important;
          line-height: 0;
          margin: 0 1rem;
          overflow: visible;
          padding: 0;
          width: auto !important;

          > .ant-btn {
            height: auto;
            margin: 0;
            padding: 2px;
            width: auto;
          }
        }
      }
    }

    a {
      color: var(--bs-body-font-family);
      font-size: 0.8rem;
      transition: none;

      &:hover {
        color: $primaryDark !important;
        fill: $primaryDark !important;
      }
    }

    svg {
      max-width: 1rem;
      min-width: 1rem;
      width: 1rem;
      max-height: 1rem;
      min-height: 1rem;
      height: 1rem;
      margin-bottom: 3px;
    }
  }

  .my-projects {
    background: linear-gradient(to bottom, #ffffff 0, var(--bs-light) 150px);
    margin-top: -100px;
    padding-top: 100px !important;
    padding-bottom: 2rem !important;
  }
}
