.tree {
  padding-bottom: 0.5rem;

  .tree-wrapper {
    overflow-y: auto;
    padding: 0.5rem 0;

    .no-childs {
      color: $gray-500;
      display: block;
      overflow: hidden;
      padding: 0 2rem 0 2.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tree-item {
    align-items: center;
    cursor: default;
    display: flex;
    justify-content: space-between;
    position: relative;
    user-select: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);

      .tree-item-actions {
        display: flex;
      }
    }

    &.tree-item--current {
      background: $primary;

      svg,
      span {
        color: #ffffff !important;
      }
    }

    &.tree-item-folder,
    &.tree-parent {
      background-color: transparent;
      .tree-item-actions {
        display: flex;
      }
    }
    &.tree-parent {
      .tree-item-body {
        svg {
          width: 1.5rem;
          min-width: 1.5rem;
          max-width: 1.5rem;
          height: 1.5rem;
          max-height: 1.5rem;
          min-height: 1.5rem;
          margin-left: -0.5rem;
        }
      }
      .tree-item-actions {
        > button {
          svg {
            color: $gray-700;
          }
        }
      }
    }

    svg {
      width: 1rem;
      max-width: 1rem;
      min-width: 1rem;
      height: 1rem;
      max-height: 1rem;
      min-height: 1rem;
    }

    .tree-item-body {
      align-items: center;
      color: $gray-600;
      display: flex;
      flex-grow: 1;
      line-height: 30px;
      max-width: 100%;
      padding: 0;
      position: relative;
      z-index: 1;

      svg {
        color: $gray-600;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .tree-item-body {
      flex-grow: 1;
      width: 0;

      .ant-space {
        width: 100%;
        .ant-space-item {
          overflow: hidden;

          &:first-child {
            min-width: 1rem;
          }

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .tree-item-actions {
      display: none;
      position: relative;
      z-index: 2;

      > button {
        background-color: transparent;
        padding: 0;
        height: 30px;
        width: 30px;
        &:last-child {
          margin-right: 0.25rem;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        svg {
          color: currentColor;
        }
      }
    }
  }
}

.add-item-modal {
  &.fullscreen-modal {
    padding: 40px;
    height: unset;
  }

  .ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    > .ant-card-cover {
      flex-grow: 1;
      background-color: $light;
    }
  }
}
