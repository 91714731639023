// -----------------------------------------------------------------------------
// This file contains all styles related to the SearchBox component.
// -----------------------------------------------------------------------------

.search-box {
  align-items: center;
  display: flex;
  height: 55px;
  justify-content: center;
  max-width: 100%;
  outline: none;
  padding: 0;
  width: 100%;
  z-index: 99;

  > div {
    background-color: transparent;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-size: 0.8rem;
    height: 100%;
    width: 100%;
    z-index: 99;

    .search.icon {
      font-size: 1.2rem;
      margin-right: 0.5rem;
      width: 1.2rem;
    }
  }

  .select__placeholder {
    color: rgba(0, 0, 0, 0.25);
  }

  .select__menu {
    background-color: #ffffff !important;
    border-radius: 0.25rem !important;
    box-shadow: 0 0.5rem 1rem rgba(#000000, 0.15) !important;
    height: auto;
    left: 0;
    margin: -40px -0.25rem 0;
    padding: 40px 1rem 0.5rem;
    width: 87vw;
    z-index: 98;
  }
}

@media (min-width: 768px) {
  .search-box {
    height: 39px;
  }
}
