.list {
  display: flex;

  > div {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &.direction-auto {
    flex-wrap: wrap;
  }
  &.direction-auto,
  &.direction-horizontal {
    flex-direction: row;
    > div {
      flex-grow: 1;
    }
  }
  &.direction-vertical {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }

  &.list-columns {
    display: grid;
  }

  &.list-columns-auto {
    flex-direction: row;
    flex-wrap: wrap;

    > div {
      width: auto;
    }
  }
}
