// -----------------------------------------------------------------------------
// This file contains all styles related to the actions component.
// -----------------------------------------------------------------------------

.premium-tag {
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: all;

  .ant-tag {
    margin: 0;
  }
}
