.cropper {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  .img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    &:before {
      content: '';
      display: block;
      padding-top: 66.66%;
    }
  }
  &[data-mode='16:9'] {
    .frame-wrapper {
      width: 80%;
      &:before {
        padding-top: 56.25%;
      }
    }
  }
  &[data-mode='9:16'] {
    .frame-wrapper {
      width: 30%;
      &:before {
        padding-top: 177.78%;
      }
    }
  }
  &[data-mode='4:3'] {
    .frame-wrapper {
      width: 70%;
      &:before {
        padding-top: 75%;
      }
    }
  }
  &[data-mode='3:4'] {
    .frame-wrapper {
      &:before {
        padding-top: 133.33%;
      }
    }
  }
  &[data-mode='free'] {
    .frame-wrapper {
      &:before {
        padding-top: 50%;
      }
    }
  }
  &[data-mode='fit'] {
    .frame-wrapper {
      height: 100%;
      width: 100%;
      &:before {
        display: none;
      }
    }
  }
  &[data-mode='square'] {
    .frame-wrapper {
      &:before {
        padding-top: 100%;
      }
    }
  }
  &[data-mode='circle'],
  &[data-mode='circle_square'] {
    .frame-wrapper {
      border-radius: 10rem;
      &:before {
        padding-top: 100%;
      }
    }
  }
  .frame-wrapper {
    align-items: center;
    color: #00000096;
    box-shadow: 0 0 0 100rem;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 40%;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    .frame {
      color: var(--primary-dark);
      border: 1px solid;
      height: 100%;
      position: absolute;
      width: 100%;
      .guides,
      .handlers {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .guides {
        color: var(--primary);
        position: absolute;
        > div {
          position: absolute;
          &:nth-child(1),
          &:nth-child(2) {
            height: 100%;
            border-left: 1px solid;
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: 100%;
            border-top: 1px solid;
          }
          &:nth-child(1) {
            left: 33.33%;
          }
          &:nth-child(2) {
            left: 66.66%;
          }
          &:nth-child(3) {
            top: 33.33%;
          }
          &:nth-child(4) {
            top: 66.66%;
          }
        }
      }
    }
    .handlers {
      color: var(--primary-dark);
      height: 100%;
      position: absolute;
      width: 100%;
      > div {
        background-color: currentColor;
        border-radius: 2rem;
        height: 2rem;
        position: absolute;
        width: 2rem;
        &:nth-child(1) {
          left: -1.1rem;
          top: -1.1rem;
        }
        &:nth-child(2) {
          right: -1.1rem;
          top: -1.1rem;
        }
        &:nth-child(3) {
          bottom: -1.1rem;
          right: -1.1rem;
        }
        &:nth-child(4) {
          bottom: -1.1rem;
          left: -1.1rem;
        }
      }
    }
  }
}
