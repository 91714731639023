.sidebar-main {
  background: linear-gradient(
    135deg,
    lighten($primary, 38%) 60%,
    lighten($secondary, 32%) 100%
  );
  padding-bottom: 1.5rem;

  > div {
    align-items: center;
    padding: 0 0.5rem;
  }

  svg {
    max-width: 1.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    max-height: 1.5rem;
    min-height: 1.5rem;
    height: 1.5rem;
  }

  .link-to-root {
    color: var(--bs-body-color);
    display: flex;
    align-items: center;
    position: relative;

    svg {
      position: absolute;
      right: 100%;
      max-width: 1.2rem;
      min-width: 1.2rem;
      width: 1.2rem;
      max-height: 1.2rem;
      min-height: 1.2rem;
      height: 1.2rem;
      fill: #000000 !important;
    }
  }

  .ant-space {
    padding: 1rem 0;
    .ant-space-item {
      padding: 0.5rem 0;
    }
  }
  .ant-menu-title-content {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    > a {
      width: 100%;
    }
  }

  .ant-menu-submenu-title {
    padding: 0;
    overflow: visible;
    > .ant-menu-title-content {
      display: none;
    }
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  .project-shortcut {
    border: 2px solid $gray-700;
    background-color: transparent;
    border-radius: 0.25rem !important;
    max-height: 30px;
    max-width: 30px;
    min-height: 30px;
    min-width: 30px;
    margin: 5px 0;

    img {
      border: 2px solid transparent;
      border-radius: 5px;
      display: block;
    }
  }

  .ant-menu {
    border-right: none !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .ant-menu-item,
    .ant-menu-submenu-title {
      &:active {
        background-color: transparent;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 0;
      display: flex;
      justify-content: center;
      overflow: visible;

      &.sidebar-menu-item {
        height: auto;

        .ant-menu-submenu-title {
          height: auto;
        }

        button {
          padding: 8px;
          width: 100%;
          height: 64px;
          display: flex;
          flex-direction: column;
          align-items: center;

          small {
            font-size: 10px;
            text-transform: uppercase;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 6px;
          }
        }
      }

      button {
        padding: 0;
        width: 40px;
        height: 40px;
      }
    }

    .ant-menu-item-divider {
      background-color: transparent;
    }
  }

  .notifications {
    [data-any-new='false'] {
      > i {
        color: darken($secondary, 10%);
      }

      &:active,
      &:focus,
      &:hover {
        > i {
          color: #ffffff;
        }
      }
    }
    .dropdown-menu {
      bottom: 10px;
      flex-direction: column;
      top: unset !important;
      transform: translateX(40px) !important;
      white-space: nowrap;
      width: 320px;

      &.show {
        display: flex;
      }
    }
    .badge {
      bottom: 20px;
      position: absolute;
      right: -5px;
      top: unset;
      z-index: 1;
    }
  }
}

.sidebar-main-menu {
  > .ant-menu-sub {
    padding: 0.5rem 0;

    .ant-menu-submenu-open {
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.85) !important;
    }
    .ant-menu-submenu-title,
    .ant-menu-item {
      height: 40px;
      line-height: 40px;
      padding: 0 1rem;
      margin: 0;

      &:not([aria-disabled='true']) {
        &:hover,
        &:active {
          background-color: rgba(0, 0, 0, 0.1);
        }
        &:hover,
        &:hover a {
          color: rgba(0, 0, 0, 0.85) !important;
        }
      }

      .ant-space {
        width: 100%;
        .ant-space-item {
          &:first-child {
            flex-grow: 1;
          }
          &:last-child {
            svg {
              color: rgba(0, 0, 0, 0.25) !important;
              margin-left: 0.5rem;
              max-width: 1rem;
              min-width: 1rem;
              width: 1rem;
              max-height: 1rem;
              min-height: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }
}

.sidebar-menu {
  background-color: lighten($primaryLight, 26%);

  .search-box-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.project {
  display: flex;
  position: relative;

  &.project--current {
    &:before {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -24px;
      right: -24px;
      background-color: lighten($primary, 35%);
      border-left: 4px solid $primary;
      z-index: -1;
    }
  }

  .ant-list-item-action {
    padding-left: 42px;
    > li {
      font-size: 0.8rem;
      .ant-space-item {
        display: flex;
        svg {
          width: 1rem;
        }
      }
    }
  }

  .btn {
    box-shadow: none !important;
    svg {
      color: $gray-700 !important;
      max-width: 1.5rem;
      min-width: 1.5rem;
      width: 1.5rem;
      max-height: 1.5rem;
      min-height: 1.5rem;
      height: 1.5rem;
    }
  }
}

.ant-popover {
  padding: 0;
  .ant-popover-arrow {
    display: none;
  }
  &.resources-popover,
  &.edit-popover,
  &.integrations-popover {
    padding: 0;
    z-index: 999;
    .ant-popover-inner-content {
      padding: 0;
    }
  }
  &.integrations-popover {
    .ant-collapse {
      background-color: #ffffff !important;
      .ant-collapse-item {
        .ant-collapse-content {
          border: none;
          .ant-collapse-content-box {
            padding-left: 60px;
            &:empty {
              display: none;
            }
          }
        }
      }
    }
  }
}
