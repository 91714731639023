.register,
.login,
.forgot {
  & > .row {
    margin: 0;
    min-height: 100vh;
    > div {
      &:first-child {
        > div {
          height: 100vh;
          position: fixed;
          transform: rotate(-15deg) scale(1.5);
          width: 40%;
        }
      }
      img {
        transform: scale(1.25) translate(10%, 0);
        z-index: 1;
      }
    }
  }
}

.grecaptcha-badge {
  position: static !important;
  top: auto;
  bottom: auto !important;
  right: auto !important;
  box-shadow: none !important;
  overflow: hidden;
  border-radius: 6px;
  transform-origin: left top;
  transform: scale(0.6);
}
