// -----------------------------------------------------------------------------
// This file contains all styles related to the alerts of the site/application.
// -----------------------------------------------------------------------------

.alert-wrapper {
  align-items: initial;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  left: 0;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  right: 0;
  width: 320px;
  z-index: 1051;

  h6 {
    background-color: #eeeeee;
    color: #999999;
    font-size: 0.8rem;
    line-height: 2rem;
    margin: 0;
    padding: 0 1rem;
    &.new {
      background-color: $primary;
      color: #ffffff;
      order: 0;
    }
    &.old {
      order: 2;
    }
  }

  > .ant-empty {
    padding: 1rem 0;
  }

  > .ant-alert {
    align-items: flex-start;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
    color: inherit;
    display: flex;
    font-size: 0.9rem;
    margin: 0;
    order: 3;
    white-space: nowrap;

    &:hover {
      background-color: $light;
    }

    &[data-new='true'] {
      background-color: rgba($primary, 0.2);
      order: 1;

      &:hover {
        background-color: rgba($primary, 0.25);
      }
    }

    .ant-alert-icon {
      font-size: 0.8rem;
    }

    .ant-alert-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 0.8rem;
      line-height: 1rem;
      overflow: hidden;
      white-space: normal;
      width: 0;

      .ant-alert-message {
        font-size: 0.8rem;

        .estimated-time {
          color: rgba($blue, 0.8);
        }

        > .from-now {
          color: rgba($gray-600, 0.6);
          margin-top: 0.15rem;
        }
      }

      .ant-alert-description {
        display: none;
        line-height: 1rem;
        overflow: hidden;
        white-space: pre-line;

        > canvas {
          box-shadow: 0 0 0 4px #ffffff;
          margin: 1rem 4px;
        }

        > ul {
          margin-bottom: 0.5rem;
          padding-left: 1.5rem;

          > li {
            margin-top: 0.5rem;
          }

          &.errors,
          &.warnings {
            color: $gray-600;
            padding-left: 0;
            a {
              color: inherit;
              display: block;

              p {
                color: $primary;
                text-align: right;
              }

              &:hover {
                color: #000000;
                p {
                  color: darken($primary, 25%);
                }
              }
            }
          }
        }
      }
    }

    &.expanded {
      .ant-alert-description {
        display: block;
      }
      .expand-button {
        color: rgba(0, 0, 0, 0.15);
      }
    }

    .ant-alert-close-icon {
      width: 20px;
      height: 25px;
      svg {
        width: 1rem;
        min-width: 1rem;
        max-width: 1rem;
        height: 1rem;
        max-height: 1rem;
        min-height: 1rem;
      }
    }

    .display-progress {
      align-items: center;
      display: flex;
      margin-left: 1rem;
      max-width: 40px;
      min-width: 40px;
      text-align: right;
      width: 40px;
    }

    > .progress {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      background-color: transparent;

      > .progress-bar {
        overflow: hidden;
        display: flex;

        > span {
          align-items: center;
          text-align: left;
          margin: 0 0 0 1rem;
          font-size: 0.9rem;
          display: flex;

          > span:first-child {
            flex-grow: 1;
          }

          > .btn {
            margin-right: 1.25rem !important;
          }
        }
      }
    }
  }
}

[data-mobile='true'] {
  .alert-wrapper {
    top: 56px;
  }
}

.errors {
  a {
    color: $danger;
  }
}

.warnings {
  a {
    color: $orange;
  }
}
