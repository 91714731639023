.drawer {
  animation-name: drawerOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  z-index: 9;
  .drawer-menu {
    background: #ffffff;
    height: 100%;
    width: 75%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1;

    .subtitle,
    .title {
      font-size: 0.8rem;
      font-weight: 500;
    }
    .subtitle {
      display: block;
      opacity: 0.7;
    }
    .title {
      display: block;
    }

    header {
      height: auto;
      padding: 1rem;
      &:empty {
        display: none;
      }

      .icon {
        width: 50px;
        display: block;
        margin-bottom: 0.5rem;
      }
    }
    .drawer-content {
      > .items {
        > .item {
          border-bottom: 1px solid #dddddd;
          padding: 1rem !important;
          &:last-child {
            border-bottom: none;
          }
          > .icon {
            margin: 0 1.2rem 0 0;
          }
          > .title {
            opacity: 0.7;
          }

          .items {
            width: 100%;
          }
        }
      }
      .item {
        align-items: center;
        display: flex;
        font-size: 0.8rem;
        flex-wrap: wrap;
        padding: 1rem 0 0 1.2rem;
        .icon {
          width: 1.2rem;
          margin: 0 1.2rem 0 -1.2rem;
        }
      }
    }
  }
  .drawer-bg {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease;
    z-index: 0;
  }

  &.drawer-shown {
    animation-name: drawerIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    .drawer-menu {
      transform: translateX(0);
    }
    .drawer-bg {
      opacity: 1;
    }
  }
}
