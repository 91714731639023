.my-projects {
  padding: 1rem 3rem;
  h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  .ant-list-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    grid-auto-rows: minmax(100px, auto);
    .ant-list-item {
      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      padding: 1rem;
    }
  }
}
