// -----------------------------------------------------------------------------
// This file contains all styles related to the actions component.
// -----------------------------------------------------------------------------

.actions {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;

  svg:not([data-icon='loading']) {
    max-width: 1rem;
    min-width: 1rem;
    width: 1rem;
    max-height: 1rem;
    min-height: 1rem;
    height: 1rem;
  }

  .ant-space,
  .ant-space-item {
    max-height: 100%;
    align-items: center;
    display: flex;
  }

  .ant-btn {
    svg {
      margin-right: 0.25rem;
    }
  }

  .autosave-switch {
    margin: 2px 0.5rem 0 0.5rem;
    height: 16px;
    display: inline-flex !important;
  }

  .actions-preview {
    .ant-btn {
      padding: 0;
    }
    .palette {
      border-radius: 1rem;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      margin-left: 0.5rem;
      > div {
        border-radius: 1rem;
        display: flex;
        height: 1rem;
        overflow: hidden;
        width: 1rem;
        > div {
          background-color: currentColor;
          flex-grow: 1;
          height: 100%;
        }
      }
    }
  }
}

.ant-dropdown-menu.palettes {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  .ant-dropdown-menu-item {
    padding: 10px;
    .palette {
      > div {
        border-radius: 1rem;
        display: flex;
        height: 1rem;
        overflow: hidden;
        width: 1rem;
        > div {
          background-color: currentColor;
          flex-grow: 1;
          height: 100%;
        }
      }
    }
  }
}
