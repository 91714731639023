// -----------------------------------------------------------------------------
// This file contains styles that are specific to integrations page.
// -----------------------------------------------------------------------------

.integrations {
    .providers-wrapper {
        background-color: rgb(188, 188, 188);
        overflow: hidden;
    }
    .providers-wrapper:a {
        color:rgb(188, 188, 188)
    }
    .provider-card-data {
        margin-bottom: 16pt;
    }
    .api-key-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        background-color: rgb(255, 255, 255);
        text-align: center;
        width: 100%;
        display: block;
    }
    .active-card {
        background-color: rgb(240, 255, 239);
    }

    .provider-list {
        background-color: transparent !important;
        overflow-y: auto;
    }
    .provider-parameters {
        text-align: left;
    }

    #provider-info {
        font-size: 10pt;
    }

    #provider-config {
        margin-top: 16pt;
    }

    #provider-config:label,input{
        display: block;
    }
    .provider-info-label {
        font-weight: bold;
        color: $primary;
    }

    .provider-edit-button {
        right: 8pt;
        bottom: 8pt;
        position: absolute;
    }

    .api-key-value {
        -webkit-user-select: all;  /* Chrome all / Safari all */
        -moz-user-select: all;     /* Firefox all */
        -ms-user-select: all;      /* IE 10+ */
        user-select: all; 
    }

}
  