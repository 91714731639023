.image {
  border-radius: inherit;
  height: inherit;
  width: 100%;

  &.cover {
    object-fit: cover;
  }

  &.contain {
    object-fit: contain;
  }
}
