.json-editor {
  height: 100%;
  width: 100%;

  + .save {
    top: 0px;
    left: 100px;
    height: 26px;
    position: absolute;
    width: 26px;
  }

  .jsoneditor {
    border: none;

    > .jsoneditor-menu,
    > .jsoneditor-navigation-bar {
      display: none;
    }

    .jsoneditor-outer {
      margin: 0;
      padding: 0;
      .ace-github {
        color: $primaryText;
        font-size: 1rem;
        .ace_gutter {
          background-color: $light;
        }
        .ace_string {
          color: #008000;
        }
        .ace_numeric {
          color: #0000ff;
        }
      }
    }

    .jsoneditor-additional-errors {
      //display: none !important;
    }

    tr {
      display: flex;
      td {
        display: block;
        max-width: unset;
        width: auto;
        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus {
  background-color: $primary;
}

.jsoneditor-modal .pico-modal-header {
  background-color: #ffffff;
  color: inherit;
  font-weight: bold;
}
.jsoneditor-modal
  .jsoneditor-button-group.jsoneditor-button-group-value-asc
  input.jsoneditor-button-asc,
.jsoneditor-modal
  .jsoneditor-button-group.jsoneditor-button-group-value-desc
  input.jsoneditor-button-desc {
  background: $primary;
  border-color: $primary;
}
.jsoneditor-modal a,
.jsoneditor-modal .jsoneditor-jmespath-label {
  color: $primary;
}
