main {
  font-weight: normal;
}

.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

// ttfs

// font
