.pager {
  overflow-x: auto;
  overflow-y: hidden;

  &[data-tabs-position='bottom'] {
    order: 2;
  }

  li {
    height: 2.75rem;
    max-height: 2.75rem;
    min-height: 2.75rem;
  }
  &[data-tab-style='icon'] {
    li {
      height: 3rem;
      max-height: 3rem;
      min-height: 3rem;
      span {
        display: none;
      }
    }
  }
  &[data-tab-style='icon_title'] {
    ul.pager-tabs {
      li {
        height: 3.75rem !important;
        line-height: 1.2rem !important;
        max-height: 3.75rem !important;
        min-height: 3.75rem !important;
        width: 100%;
        span {
          font-size: 0.7rem;
          font-weight: bold;
        }
      }
    }
  }
  &[data-tab-style='title'] {
    .icon {
      display: none;
    }
  }

  .pager-index {
    bottom: 0;
    height: 3px;
    position: absolute;
    transition: all 0.3s ease;
    z-index: 0;
  }
  ul.pager-tabs {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      flex-grow: 1;
      line-height: 3rem;
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;

      .icon {
        background-color: currentColor;
        height: 26px;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 26px;
        width: 100%;
      }
    }
  }
}
