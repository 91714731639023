// -----------------------------------------------------------------------------
// This file contains all styles related to the drop-wrapper component.
// -----------------------------------------------------------------------------

.drop-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 1rem;
  z-index: 1049;
  visibility: hidden;

  > .bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30%;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.3s ease;
  }

  > .ant-alert {
    position: absolute;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    transform: translate(0, -200px);
    transition: transform 0.3s ease;
    transition-delay: 0.1s;
    z-index: 1;

    .ant-alert-message {
      > strong {
        display: inline-block;
        > .ant-input {
          display: none;
        }
      }
    }
    .folder {
      display: none;
    }
  }
  > .input {
    display: none;
  }
  &.show {
    visibility: visible;
    > .bg {
      transform: scale(1);
    }
    > .ant-alert {
      transform: translate(0, 0px);
    }
  }

  .d-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.overwrite-modal {
  .overwrite-preview {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    padding: 1rem 0;
    white-space: nowrap;

    &:first-child {
      margin: 0;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }

    > div {
      &:first-child {
        display: flex;
        flex-grow: 1;
        margin-bottom: 0.5rem;

        > div {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      &:last-child {
        align-items: center;
        display: flex;
        input {
          margin: 0.1rem 0.5rem 0.2rem;
        }
        label {
          margin: 0;
        }
      }
    }

    .old-file,
    .new-file {
      border: 1px solid $gray-300;
      padding: 1rem;
    }

    .delete-x {
      align-self: center;
      color: $danger;
      display: none;
      font-size: 5rem;
      padding-bottom: calc(22px + 0.5rem);
      position: absolute;
      text-shadow: 0 0 2rem #fff;
      width: 6rem;
      z-index: 1;
    }

    .preview {
      height: 100px;
      overflow: visible;
      > * {
        max-height: 100px;
        max-width: 200px;
      }

      .img {
        height: 100%;
        transform: none !important;
      }
    }

    span {
      display: block;
      margin-top: 0.5rem;

      &.keep-both-name,
      &.overwrite-name {
        display: none;
      }
    }

    &[data-overwrite='true'] {
      > div:first-child {
        border: 1px solid $gray-300;
        > div {
          border: none;
        }
      }
      .old-file {
        span {
          visibility: hidden;
        }
        .preview {
          opacity: 0.4;
        }
      }
      .delete-x,
      .overwrite-name {
        display: block;
      }
    }
    &[data-overwrite='false'] {
      .old-file {
        margin-right: 1rem;
      }
      .keep-both-name {
        display: block;
      }
    }
  }
}

.drop-wrapper-modal {
  padding: 2rem;

  .ant-modal-body {
    padding: 0;
  }

  .drop-wrapper {
    text-align: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 0;
    position: relative;
    min-height: 200px;
    visibility: visible;

    .ant-alert {
      position: relative;
      transform: translate(0, 0px);
      background: transparent;
      border: none;
      box-shadow: none !important;
      color: inherit;

      strong {
        align-items: center;
        justify-content: center;
        display: flex;
        white-space: nowrap;
        > .ant-input {
          display: inline-block;
        }
      }
      .folder {
        display: block;
        font-size: 0.8rem;
        height: 1.5rem;
        padding: 0 0.25rem;
      }
    }
    &.show {
      .droppable-zone {
        border-color: $primary;
      }
    }
  }

  .ant-tabs {
    width: 100%;
    &[data-main-folder='res'] {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap,
        .ant-tabs-nav-operations {
          display: flex;
        }
      }
    }

    .ant-tabs-nav {
      background: rgba(0, 0, 0, 0.05);
      flex-wrap: wrap;

      .ant-tabs-nav-wrap,
      .ant-tabs-nav-operations {
        display: none;
      }

      .ant-tabs-extra-content,
      .ant-tabs-nav-wrap {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    .ant-tabs-extra-content {
      padding: 1rem;
      width: 100%;
      background: rgba(0, 0, 0, 0.05);
    }

    .ant-tabs-content-holder {
      padding: 1rem;
    }

    .droppable-zone {
      border: 2px dashed $gray-300;
      border-radius: 0.25rem;
      padding: 1rem;
    }
  }
  .ant-tabs-content {
    height: 100%;
  }
}

.pexels-wrapper {
  display: flex;
  flex-direction: column;
  .pexels-header {
    margin-bottom: 1rem;
    width: 100%;
  }
  .pexels {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    .d-grid {
      > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        &:before {
          background-color: $primary;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          transition: opacity 0.1s ease;
          will-change: opacity;
        }
        img,
        video {
          display: block;
          transform: scale(1);
          transition: filter 0.1s ease;
          will-change: filter;
        }
        footer {
          background: linear-gradient(
            to bottom,
            rgba(#000000, 0),
            rgba(#000000, 0.6)
          );
          bottom: 0;
          color: $gray-400;
          left: 0;
          opacity: 0;
          padding: 3rem 1rem 0.5rem;
          position: absolute;
          right: 0;
          transition: opacity 0.2s ease;
          will-change: opacity;
          z-index: 4;
          a {
            color: inherit;
            text-decoration: underline !important;
          }
        }
        &.ant-popover-open,
        &:hover {
          &:before {
            opacity: 0.4;
            z-index: 3;
          }
          img,
          video {
            filter: brightness(1.2) saturate(1.5);
            z-index: 2;
          }
          footer {
            opacity: 1;
          }
        }
      }
    }
  }

  [data-orientation='landscape'] {
    .d-grid {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
  }
  [data-orientation='portrait'] {
    .d-grid {
      grid-template-columns: repeat(auto-fit, minmax(173px, 1fr));
    }
  }
}

@media (min-width: 600px) {
  :root {
    --results-columns: 3;
  }
}

@media (min-width: 800px) {
  :root {
    --results-columns: 4;
  }
}

@media (min-width: 1000px) {
  :root {
    --results-columns: 5;
  }
}

@media (min-width: 1200px) {
  :root {
    --results-columns: 6;
  }
}
