main {
  > .upload-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100%;
    width: 100%;
  }

  .directory-select {
    z-index: 2;
  }

  [data-view='list'] {
    tbody {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100px;
      }
    }
  }

  [data-view='grid'] {
    display: flex;
    flex-direction: column !important;

    [data-hide-on='grid'],
    [data-hide-on='grid,mobile'] {
      display: none !important;
    }

    thead {
      tr {
        flex-grow: 1;
        display: flex;

        &:nth-child(2) {
          th {
            border-top: none;
          }
        }

        th {
          &:last-child {
            max-width: calc(100% - 30px);
            min-width: calc(100% - 30px);
            width: calc(100% - 30px);
            .dropdown {
              margin: 0 0 0 auto;
              width: 30px;
            }
          }
        }
      }
    }

    tbody {
      display: flex;
      flex-wrap: wrap;

      tr {
        display: flex;
        justify-content: center;
        min-width: 200px;
        max-width: 200px;
        width: 200px;
        margin-top: 1rem;
        margin-right: 1rem;
        text-align: center;
        background-color: transparent !important;

        td {
          display: none;
          border: none;

          &:first-child {
            display: block;
            margin-right: -1.5rem;
          }

          &:nth-child(2) {
            width: 100%;
            max-width: 100%;
            display: block;
          }

          .preview {
            align-items: center;
            display: flex;
            height: 90px;
            min-height: 0;
            width: 100% !important;

            i {
              font-size: 4rem !important;
            }

            h6 {
              display: none;
            }

            audio {
              width: 100%;
            }

            i,
            video {
              height: 100%;
              margin: 0 auto;
              width: auto !important;
            }

            .btn {
              display: none !important;
            }
            .preview-body {
              padding: 0 0 0.5rem;
            }
            .preview-footer {
              display: none;
            }
          }

          .d-flex {
            flex-direction: column;
            align-items: center;

            .placeholder-image {
              margin: 0 0 0.5rem !important;
              width: 100px !important;
            }
            .placeholder-text {
              max-width: 100%;
            }
            .mdi {
              width: 100px !important;
              margin: 0 !important;
              font-size: 4rem !important;
            }
          }

          .btn {
            display: block !important;
            text-align: center;
            span {
              display: block;
              margin: 0 auto !important;
            }
          }
        }

        &[data-checked='true'] {
          td {
            border-radius: 0 $border-radius $border-radius 0;
            &:first-child {
              border-radius: $border-radius 0 0 $border-radius;
            }
          }
        }
      }
    }
  }

  [data-unlistened='true'] {
    font-weight: bold;
  }

  .file-player-wrapper {
    margin-top: -72px;
    .wave-wrapper {
      width: 100% !important;
      margin-bottom: -50px;
    }
    .preloader {
      background: linear-gradient(transparent, rgba($secondary, 0.25));
      padding: 90px 0 40px;
    }
  }
}

.share-file {
  .check {
    border-radius: 3px;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: $secondary;
    color: #fff;
    font-size: 26px;
  }
  .copied {
    position: relative;
    .check {
      opacity: 0;
      display: flex;
      animation-name: fadeOut;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }
}

[data-mobile='true'] {
  [data-hide-on='mobile'],
  [data-hide-on='grid,mobile'] {
    display: none;
  }
  .item-list {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  table {
    display: flex;
    flex-direction: column;
    margin-top: -1px;

    tbody {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        align-items: center;
        border-top: 1px solid #ddd;

        td {
          border: none;
          &:nth-child(2) {
            max-width: 100%;
          }
        }
      }
    }

    .custom-checkbox {
      opacity: 1 !important;
    }
  }

  .tooltip {
    display: none;
  }
}

@media (max-width: 768px) {
  [data-hide-on='mobile'],
  [data-hide-on='grid,mobile'] {
    display: none;
  }
}
