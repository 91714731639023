.cursor-pointer {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.border-top {
  border-top: 1px solid var(--bs-gray-300);
}


.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-10 {
  z-index: 10;
}


// component