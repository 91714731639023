[data-project-type='mobile_app'] {
  .group {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    > :not(.group) {
      flex: none;
    }
    > .group {
      flex: auto;
      flex-grow: 0;
    }

    &.direction-auto {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &.direction-vertical {
      flex-direction: column;
      > [data-component-id].w-fill {
        width: 100% !important;
        &:not(.h-fill) {
          flex-grow: 0 !important;
        }
      }
    }

    &.direction-horizontal {
      flex-direction: row;
      > [data-component-id].h-fill {
        height: 100% !important;
        &:not(.w-fill) {
          flex-grow: 0 !important;
        }
      }
    }

    &.scrollable {
      &.direction-vertical {
        overflow-x: hidden !important;
        overflow-y: auto !important;
      }
      &.direction-horizontal {
        overflow-x: auto !important;
        overflow-y: hidden !important;
      }
    }
  }
}
