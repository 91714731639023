.preloader {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  z-index: 10;
  user-select: none;
}

.preloader ~ * .preloader {
  display: none !important;
}
