// -----------------------------------------------------------------------------
// This file contains styles that are specific to the sources page.
// -----------------------------------------------------------------------------

.sources {
  .d-sources-none {
    display: none !important;
  }

  .card {
    flex-grow: 0;
    width: 340px;
    min-width: 340px;
    margin-bottom: 1.5rem !important;
  }

  .custom-control {
    padding: 0;
  }
}

.checkout {
  .d-checkout-none {
    display: none !important;
  }
  .card-deck {
    flex-direction: column;

    .card {
      border: none;
      margin-top: 0.5rem !important;

      .card-body {
        padding: 0;

        .custom-control-input,
        .custom-control-label {
          display: block !important;
        }

        .custom-control-label {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;

          &:before,
          &:after {
            left: 0;
          }
        }
        .row {
          align-items: center;

          .col {
            padding: 0 0 0 1rem;

            img {
              width: 32px !important;
            }

            .text-content {
              > * {
                display: inline-block !important;
                margin: 0.3rem 0.5rem 0.3rem 0 !important;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
