.pricing {
  .jumbotron {
    background: linear-gradient(180deg, $primary, darken($primary, 20%));
    overflow: hidden;
    padding: 0 0 150px;

    > .container {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 3;
    }

    > .waves {
      bottom: 0;
      position: absolute;
      width: 100%;
      height: 150px;
      z-index: 2;
    }
  }
  .plans {
    table {
      thead {
        top: 67px;
      }
      td {
        thead {
          top: 117px;
        }
      }
    }
  }
}

.plans {
  table {
    margin: 0;
    thead {
      box-shadow: inset 0 0 0 2px $gray-300;
      position: sticky;
      top: -1px;
      z-index: 1;
    }
    td,
    th {
      background-color: #ffffff;
      border-top: 1px solid $gray-300;
      &:first-child {
        width: auto;
      }
      &:not(:first-child) {
        text-align: center;
        width: 150px;
        max-width: 150px;
        min-width: 150px;
      }
      &.highlighted {
        background-color: $gray-300;
      }
      thead {
        background-color: $gray-300;
        top: 87px;
        z-index: 0;
      }
      i {
        font-size: 1.5rem;
      }
    }
  }
}
