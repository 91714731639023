// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.home {
  .jumbotron {
    background: linear-gradient(180deg, $primary, darken($primary, 20%));
    height: 115vh;
    overflow: hidden;
    padding: 0;

    > .container {
      align-items: center;
      display: flex;
      height: calc(100vh - 140px);
      justify-content: center;
      z-index: 1;
    }

    > .waves {
      bottom: 0;
      position: absolute;
    }
  }

  .marketing {
    font-size: 16px;
    color: #5a5a5a;

    h2 {
      font-weight: 400;
    }

    .row {
      .col-lg-4 {
        margin-bottom: 1.5rem;
        text-align: center;

        p {
          margin-right: 0.75rem;
          margin-left: 0.75rem;
        }
      }

      &.featurette {
        .featurette-divider {
          margin: 5rem 0;
        }

        .featurette-heading {
          font-weight: bold;
          line-height: 1.3;
          letter-spacing: -0.05rem;
          color: $secondary;
        }

        .lead {
          font-size: 1rem;
          color: $gray-800;
        }

        img {
          width: 500px;
          height: auto;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .featurette-heading {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
