.action-bar {
  align-items: center;
  display: flex;
  font-weight: 500;
  height: 2.75rem;
  line-height: 2.75rem;
  max-height: 2.75rem;
  min-height: 2.75rem;
  padding: 0 1rem;
  .icon {
    max-width: 1.25rem;
    min-width: 1.25rem;
    width: 1.25rem;
    max-height: 1.25rem;
    min-height: 1.25rem;
    height: 1.25rem;
    margin-right: 1.25rem;
  }
}
