// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Basic styles for placeholders
 */
::placeholder {
  color: #ccc !important;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  background-color: #ffffff;
  user-select: none;
}

body {
  > iframe {
    display: none !important;
  }
}

/**
 * Set up the screen as the minimum height
 */
html,
body,
#app {
  min-height: 100vh;
}
#app {
  display: flex;
  flex-direction: column;
  > section {
    flex-grow: 1;
    height: 0;
    section {
      height: 100%;
    }
  }
}

#beta {
  &:after {
    content: 'beta';
    position: fixed;
    width: 80px;
    height: 19px;
    background: $yellow;
    top: 5px;
    left: -24px;
    text-align: center;
    font-size: 11px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: $secondary;
    line-height: 22px;
    transform: rotate(-45deg);
    z-index: 99;
  }
}

.no-beta ~ button > #beta {
  display: none;
}

main {
  position: relative;
  min-height: 100vh;
  height: auto !important;

  &.disabled {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999;
    }
  }
}

[contenteditable] {
  user-select: text;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for link
 */

a {
  text-decoration: none !important;
}

bloquote {
  line-height: 1.5;
}

.modal {
  pre {
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding: 0.8em 0.6em;
    width: auto;
    max-height: 600px;
    overflow: auto;
    font-family: Consolas, sans-serif !important;
    font-size: 13px;
    background-color: $gray-300;
    border-radius: 3px;
  }
}
