.nav {
  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      .image {
        width: 32px;
      }
    }
  }
}
