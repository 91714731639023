.banner {
  align-items: center !important;
  display: flex;
  justify-content: center !important;
  width: 100%;
  &:before {
    content: '';
    padding-top: 15%;
    display: block;
  }
  > img {
    position: absolute;
    width: 100%;
  }
}
