// -----------------------------------------------------------------------------
// This file contains all styles related to the color-picker component.
// -----------------------------------------------------------------------------

.color-picker {
  .color-picker-swatch {
    border-radius: 3px;
    display: inline-block;
    height: 1rem;
    margin-right: 0.25rem;
    position: relative;
    width: 1rem;

    &:before,
    &:after {
      border-radius: 3px;
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
    }

    &:before {
      background-image: -moz-linear-gradient(45deg, #ccc 25%, transparent 25%),
        -moz-linear-gradient(-45deg, #ccc 25%, transparent 25%),
        -moz-linear-gradient(45deg, transparent 75%, #ccc 75%),
        -moz-linear-gradient(-45deg, transparent 75%, #ccc 75%);
      background-image: -webkit-gradient(
          linear,
          0 100%,
          100% 0,
          color-stop(0.25, #ccc),
          color-stop(0.25, transparent)
        ),
        -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, #ccc), color-stop(0.25, transparent)),
        -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.75, transparent), color-stop(0.75, #ccc)),
        -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.75, transparent), color-stop(0.75, #ccc));

      -moz-background-size: 10px 10px;
      background-size: 10px 10px;
      -webkit-background-size: 10px 10px; /* override value for shitty webkit */

      background-position: 0 0, 5px 0, 5px -5px, 0px 5px;
    }

    &:after {
      background-color: inherit;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    }

    &:hover {
      &:after {
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4), 0 0 4px;
      }
    }
  }

  .color-picker-picker {
    position: absolute;
    z-index: 9;
  }

  .color-picker-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8;
  }
}
