@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/keyframes',
  'abstracts/mixins';

// 2. Vendors
@import '~antd/dist/antd.css', 'vendors/antd-custom', 'vendors/bootstrap-custom',
  'vendors/jsoneditor-custom', 'vendors/react-select-custom',
  '~bootstrap/scss/bootstrap', '~cookieconsent/build/cookieconsent.min.css',
  '~react-intl-tel-input/dist/main.css';

// 3. Base stuff
@import 'base/base', 'base/helpers';

// 4. Layout-related sections
@import 'layout/alert', 'layout/header', 'layout/footer', 'layout/forms',
  'layout/layout', 'layout/preview', 'layout/sidebar';

// 5. Components
@import 'components/actions', 'components/autocomplete', 'components/button',
  'components/color-picker', 'components/drop-wrapper',
  'components/event-editor', 'components/editor', 'components/file-preview',
  'components/input-password', 'components/json-editor', 'components/loader',
  'components/my-projects', 'components/new-project', 'components/placeholder',
  'components/premium-tag', 'components/search', 'components/select',
  'components/stripe', 'components/tree';

// 6. Page-specific styles
@import 'pages/authenticate', 'pages/home', 'pages/premium', 'pages/checkout',
  'pages/dashboard', 'pages/main', 'pages/pricing', 'pages/sources',
  'pages/terms', 'pages/upgrade', 'pages/verify-email', 'pages/reset-password',
  'pages/not-found', 'pages/integrations';

// 7. Themes
@import 'themes/default';
