.eye {
  font-size: 1.5rem;
  padding: 0 0.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  align-items: center;
  display: flex;
  box-shadow: none !important;

  &[data-type='text'] {
    .mdi-eye {
      display: block !important;
    }

    .mdi-eye-off {
      display: none;
    }
  }
}
