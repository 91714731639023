// -----------------------------------------------------------------------------
// This file contains styles that are specific to the upgrade page.
// -----------------------------------------------------------------------------

.upgrade {
  .card-deck {
    min-height: 10rem;

    .card {
      min-width: 220px;
    }
  }

  table {
    thead {
      top: -17px;
    }
    td,
    th {
      thead {
        top: 32px;
      }
    }
  }
}
