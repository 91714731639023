// -----------------------------------------------------------------------------
// This file contains all styles related to the color-picker component.
// -----------------------------------------------------------------------------

.autocomplete-wrapper {
  display: inline-block;

  &[data-ui='dropdown'] {
    align-items: center;
    cursor: pointer;
    caret-color: transparent;
    display: flex;

    [contenteditable]:focus {
      border: none;
    }

    .autocomplete {
      .autocomplete-suggestions {
        border-radius: 0.5rem;
      }
    }
  }

  .autocomplete {
    position: absolute;
    overflow: visible;
    z-index: 9999;

    .autocomplete-suggestions {
      background: #fff;
      border: 1px solid $gray-400;
      list-style: none;
      margin: 0;
      max-height: 400px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;

      &:empty {
        display: none;
      }

      .suggestions-option {
        cursor: default;
        padding: 0.2rem 1rem;
        white-space: nowrap;

        &.highlighted {
          background: $gray-200;
        }

        > i {
          font-size: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}
