[data-project-type='web'] {
  .nav-link {
    text-decoration: none !important;
  }

  .accordion,
  .progress {
    width: 100% !important;
  }

  .modal-backdrop {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
  }
}
