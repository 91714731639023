// -----------------------------------------------------------------------------
// This file contains all styles related to the editor component.
// -----------------------------------------------------------------------------

.node-wrapper {
  font-family: consolas, monaco, 'lucida console', 'courier new', courier,
    monospace, sans-serif;
  line-height: 1.5rem;
  margin: 0;
  position: relative;

  &:empty {
    display: none !important;
  }

  .node-wrapper {
    align-items: flex-start;
    box-shadow: none !important;
    min-height: 2rem;
    width: 100%;

    &:after,
    &:before {
      display: none !important;
    }
  }

  .node-item {
    &[data-component-id],
    &[data-event-id],
    &.node-new-item {
      &:before {
        content: '';
        border-left: 1px solid currentColor;
        position: absolute;
        left: -2px;
        top: 0;
        bottom: 0;
        box-shadow: 0 0 0 0 currentColor;
        transition: box-shadow 0.2s ease;
        will-change: box-shadow;
      }
    }

    &[data-key='content'],
    &[data-key='items'] {
      display: flex !important;
      box-shadow: none;
      width: 100%;

      [data-component-id],
      [data-key='content'],
      [data-key='items'] {
        display: flex !important;
      }
    }

    &.active,
    &.ant-popover-open,
    &.hover {
      &:before {
        box-shadow: 0 0 0 2px currentColor;
      }
    }
    &.sortable-chosen {
      background-color: currentColor;
      box-shadow: 0 0 0 2px currentColor;

      .node-expand-button,
      .node-item,
      .item-actions {
        display: none !important;
      }

      .drag-handler {
        &:active {
          cursor: grabbing;
        }
      }
      > .node-value {
        &[data-type='object'] {
          > .node-type {
            color: #ffffff !important;
          }
        }
      }
    }

    > .node-value {
      margin: 0;

      &[data-type='object'] {
        > .node-wrapper {
          margin-top: 0.5rem;

          > .node-item {
            padding: 0;
            border: none;
          }
        }
      }
    }
  }

  .node-type {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;
    color: $gray-900;
    font-size: 0.8rem;
    line-height: 30px;
    padding-left: 20px;
    user-select: none;
    white-space: nowrap;

    > span {
      overflow: hidden;
      flex-grow: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 0;
    }
  }

  .node-wrapper {
    box-shadow: -1px 0 0 rgba($secondary, 0.1);
  }

  > .node-wrapper {
    box-shadow: none;
  }

  .node-item {
    padding: 0.25rem 0 0.25rem 0.5rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 100%;
    transition: all 0.2s ease;
    width: 100%;

    .node-expand-button {
      background-color: transparent;
      border-radius: 1rem;
      cursor: default;
      color: currentColor;
      height: 24px;
      line-height: 0;
      margin: 4px 0 0 -6px;
      padding: 0;
      position: absolute;
      width: 24px;
      z-index: 1;

      svg {
        color: currentColor;
        transition: color 0.2s ease;
        will-change: color;
      }
    }

    .comments {
      color: $gray-500 !important;
      font-style: italic !important;
      margin: 0 1.75rem !important;
      position: relative;
    }

    &[data-key='comments'] {
      order: -1;
      > .node-key,
      > .node-dots,
      .node-wrapper-symbol {
        display: none;
      }
      > .node-value {
        margin-left: 0;

        > .remove-item-button {
          margin-top: 1rem !important;
        }
        > .autocomplete-wrapper {
          > div {
            color: $gray-500 !important;
            font-style: italic !important;
            max-height: unset !important;
            overflow: visible !important;
            margin: 1rem 0 !important;
            &:before,
            &:after {
              content: '';
              height: 0 !important;
              border-top: 2px dashed $gray-400 !important;
              position: absolute !important;
              left: 0.35rem !important;
              right: 0.35rem !important;
              margin: 0 !important;
            }
            &:before {
              top: -0.5rem !important;
            }
            &:after {
              bottom: -0.5rem !important;
            }
          }
        }
      }
    }

    &[data-content='true'] {
      order: 2;
    }

    &.active,
    &.hover {
      > .node-value {
        > .node-wrapper {
          box-shadow: -1px 0 0 $primary;
          &:before,
          &:after {
            content: '';
            border-top: 1px solid $primary;
            box-shadow: -1px 0 0 $primary;
            position: absolute;
            left: 0;
            width: 10px;
          }
          &:before {
            top: 0;
          }
          &:after {
            bottom: 0;
          }
        }
      }
    }

    .node-value {
      display: block;
      flex-grow: 1;
      position: relative;
      width: 0;

      &[data-expanded='false'] {
        .node-item {
          display: none !important;
        }
      }

      > .node-wrapper {
        display: flex;
        flex-direction: column;
      }

      > a {
        align-items: center;
        border: 1px solid #ddd;
        border-radius: 1rem;
        height: 26px;
        border-color: $gray-400;
        border-radius: 3rem;
        color: $gray-600;
        display: flex;
        justify-content: center;
        order: 1;
        position: relative;
        line-height: 0;
        width: 26px;
        min-width: 0;
        padding: 0;
        border-radius: 3rem;
        transition: background 0.3s ease;
        justify-content: center;
        display: flex;
        margin: 0 0 0 0.5rem;
        will-change: background;

        &:hover {
          background-color: $primary;
          border-color: $primary;
          color: #ffffff;
        }

        svg {
          max-width: 1rem;
          min-width: 1rem;
          width: 1rem;
          max-height: 1rem;
          min-height: 1rem;
          height: 1rem;
        }
      }

      i {
        font-size: 1.5rem;
      }

      &.line-through {
        .node-type {
          text-decoration: line-through;
          text-decoration-color: $danger;
          text-decoration-thickness: 2px;
        }
      }
    }

    &.node-new-item {
      background-color: transparent;
      box-shadow: none !important;
      color: $gray-900;
      padding: 0;
      position: relative;
      width: 100%;
      display: flex;
      top: unset;
      bottom: unset;
      margin: 0 0 12px 0;
      visibility: hidden;
      order: 2;

      > * {
        visibility: visible;
      }

      .clone-item-button {
        cursor: pointer;
        width: auto;
        height: 32px;
        padding: 0.15rem 0.5rem 0.15rem 1.5rem;
        border: 1px solid currentColor;
        border-radius: 0 1rem 1rem 0;
        margin-left: -1.25rem;
        box-shadow: none !important;
        transition: background 0.3s ease;
        &:hover,
        &:focus {
          background-color: currentColor !important;
          > svg {
            color: #ffffff !important;
          }
        }
      }

      > .node-wrapper-symbol {
        color: $gray-400;
        width: 100%;
        & ~ .autocomplete-wrapper {
          margin: 0 0 0.5rem 1.5rem;
        }
      }
      > .autocomplete-wrapper {
        margin: 0 0 0px -2px;
        > .autocomplete {
          color: $gray-900;
        }
        > [contenteditable] {
          background-color: #ffffff;
          border: 1px solid currentColor;
          border-radius: 0px 1rem 1rem 0;
          display: inline-block;
          min-height: 32px;
          outline: none;
          padding: 4px 16px 4px 8px;
          position: relative;
          transition: background-color 0.3s ease;
          will-change: background-color;
          &:before {
            transition: color 0.2s ease;
          }
          &:empty {
            cursor: pointer;
            width: 32px;
            &:hover,
            &:focus {
              background-color: currentColor !important;
              &:before {
                color: #ffffff !important;
                filter: brightness(1);
              }
            }
            &:before {
              content: '+' !important;
              border: none !important;
              cursor: pointer;
              line-height: 20px;
              margin: 0 0 0 4px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              filter: brightness(0.6);
            }
          }
        }
      }
      > .node-value > .autocomplete-wrapper {
        z-index: 2;
        > [contentEditable] {
          border-color: $gray-400;
          min-width: 0.25rem;
        }
      }
    }

    .item-button {
      margin: 0;
      &.remove-item-button {
        order: 4;
      }
    }

    &.active,
    &.hover {
      > .node-value > .item-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .item-actions {
    background: linear-gradient(to right, transparent, #ffffff 2rem);
    display: flex;
    order: 5;
    opacity: 0;
    overflow: hidden;
    padding: 0.25rem;
    padding-left: 2.5rem;
    position: absolute;
    right: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1;

    .check-item-button {
      > input {
        display: none !important;
      }
      > input {
        & ~ label {
          margin: 0;
          .checkbox-blank {
            display: block !important;
          }
          .checkbox-marked {
            display: none !important;
          }
        }
        &:checked {
          & ~ label {
            .checkbox-blank {
              display: none !important;
            }
            .checkbox-marked {
              display: block !important;
            }
          }
        }
      }
    }
  }

  .item-button {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin: -3px 0 -3px 0.5rem;
    padding: 0;
    width: 23px;
    svg {
      display: inline-block;
      max-width: 1.2rem;
      min-width: 1.2rem;
      width: 1.2rem;
      max-height: 1.2rem;
      min-height: 1.2rem;
      height: 1.2rem;
      line-height: 1.5rem;
    }
    > span {
      margin: 0 0.5rem 0 0.2rem;
      display: none;
    }

    &.remove-item-button {
      font-size: 1rem;
      color: $danger;
      min-width: 30px;
      &:hover {
        color: darken($danger, 20%);

        & ~ .node-wrapper {
          box-shadow: -2px 0 0 $danger;
          &:before,
          &:after {
            content: '';
            border-top: 2px solid $danger;
            box-shadow: -2px 0 0 $danger;
            position: absolute;
            left: 0;
            width: 10px;
          }
          &:before {
            top: 0;
          }
          &:after {
            bottom: 0;
          }
        }
      }
    }
  }

  &:hover {
    > .item-button {
      display: inline-block;
    }
  }
}

.preview-wrapper.preview-on:not(.d-none) {
  + .node-wrapper {
    padding-right: 386px !important;
  }
}

body.content-sorting {
  .node-wrapper {
    .node-wrapper {
      border: 1px dashed currentColor;
      min-height: 40px !important;
      margin: -1px -1px -1px -2px;
      padding-left: 1px;
      .item-actions {
        display: none !important;
      }
      .node-new-item {
        > * {
          visibility: hidden;
        }
      }
    }
  }
}
