.youtube {
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 56.3%;
  }
  > iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
