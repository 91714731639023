// -----------------------------------------------------------------------------
// This file contains all styles related to the layout of the site/application.
// -----------------------------------------------------------------------------

#app {
  > .ant-alert {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.ant-layout {
  height: 100vh;
  max-height: 100vh;
  background: #ffffff;

  .ant-layout-sider {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-layout-header {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
  }

  .ant-layout-content {
    overflow-x: hidden;
    overflow-y: auto;

    > .json-node-wrapper {
      min-height: 100%;
      width: 100%;
      padding: 1rem;
    }
  }
}

.react-resizable {
  position: relative;

  .resize-handler {
    position: absolute;
    background-color: $primary;
    opacity: 0;
    transition: all 0.2s ease;
    will-change: opacity;
    z-index: 9;

    &:hover,
    &.active {
      opacity: 1;
    }

    &.resize-handler-n,
    &.resize-handler-s {
      cursor: ns-resize;
      left: 0;
      right: 0;
      height: 3px;
    }
    &.resize-handler-n {
      top: -1px;
    }
    &.resize-handler-s {
      bottom: -1px;
    }

    &.resize-handler-e,
    &.resize-handler-w {
      cursor: ew-resize;
      top: 0;
      bottom: 0;
      width: 3px;
    }
    &.resize-handler-e {
      right: -1px;
    }
    &.resize-handler-w {
      left: -1px;
    }
  }

  .header {
    align-items: center;
    background-color: $gray-100;
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color);
    display: flex;
    height: 41px;
    margin: 1.5rem 0 0;
    padding: 0.5rem 1.5rem;
    position: sticky;
    z-index: 4;

    &:nth-of-type(1) {
      margin-top: 0;
      top: 0;
    }
  }
}

.component-panel {
  background: #ffffff;
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color);
  max-width: 0;
  position: relative;
  right: 0;
  transition: max-width 0.2s ease;
  will-change: max-width;

  &.opened {
    max-width: 800px;
  }
  .close {
    cursor: pointer;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }
  .component-parameters {
    height: 100%;
    overflow: auto;
  }
}

.event-panel {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 0;
  bottom: 0;
  transition: max-height 0.2s ease;
  will-change: max-height;
  width: 0 !important;

  &.opened {
    max-height: 800px;
    width: 100% !important;
  }
  .close {
    cursor: pointer;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }
  > .node-event-wrapper {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    align-items: center;
  }
}

.visibility-hidden {
  visibility: hidden;
}
