.switch {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    input[type='checkbox'] {
      height: 0;
      width: 0;
      visibility: hidden;

      &:checked + label {
        &:after {
          background-color: $primary;
          left: calc(100% - 1.4rem);
        }
      }

      & + label {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 1rem;
        margin: 0.5rem;
        position: relative;
        width: 2.5rem;

        &:before {
          background-color: $gray-500;
          box-shadow: inset 0 0 0 1rem rgba(0, 0, 0, 0.2);
          border-radius: 1rem;
          content: '';
          height: 100%;
          transition: background-color 0.3s ease;
          width: 100%;
          will-change: background-color;
        }

        &:after {
          background-color: #fff;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15),
            0 0.15rem 0.15rem rgba(0, 0, 0, 0.25);
          border-radius: 2rem;
          content: '';
          height: 1.4rem;
          left: 0;
          min-height: 1.4rem;
          min-width: 1.4rem;
          position: absolute;
          transition: all 0.2s ease;
          width: 1.4rem;
          will-change: left, background-color;
        }
      }
    }
  }
}
