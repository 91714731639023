// -----------------------------------------------------------------------------
// This file contains styles that are specific to plans page.
// -----------------------------------------------------------------------------

.plans {
  iframe {
    border: none;
    height: 100vh;
    width: 100%;
  }
  .card-deck {
    min-height: 10rem;
    justify-content: center;

    .card {
      min-width: 220px;
      max-width: 500px;

      .card-header {
        min-height: 210px;
        position: relative;
        color: #fff;
        background-color: $primary;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        padding: 2rem 2rem 5rem;
      }
    }
  }
}
