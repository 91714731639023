.button {
  align-items: center;
  border-radius: 4rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  > div {
    background-position: center;
    background-size: contain;
    height: 2rem !important;
    width: 2rem !important;
    background-size: 95%;
    background-repeat: no-repeat;
  }
}
