.cursor-pointer {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.border-top,
.border-end,
.border-bottom,
.border-start {
  &.form-select,
  &.form-control,
  > .form-select,
  > .form-control {
    border: none;
  }
}

.border-bottom {
  &:not(.border-top.border-end.border-bottom) {
    border-radius: 0 !important;
  }
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-10 {
  z-index: 10;
}

// component
